var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"intro_product",class:{ intro_book: _vm.mapContentType === 'cover' },style:({ background: _vm.introTheme })},[_c('span',{staticClass:"bg_keyvisual",style:({
      background:
        _vm.mapContentType === 'cover'
          ? ("url(" + (_vm.contentInfo.getThumbnail(
              'cover'
            )) + ") 50% 50% / cover no-repeat")
          : ("url(" + (_vm.contentInfo.getKeyVisual()) + ")")
    })}),_c('div',{staticClass:"inner_product"},[_c('div',{staticClass:"info_product"},[_c('div',{staticClass:"desc_product"},[(_vm.restrictedAge)?_c('icon-age',{attrs:{"restrictedAge":_vm.restrictedAge}}):_vm._e(),_c('span',{staticClass:"blind"},[_vm._v("장르")]),_vm._v(_vm._s(_vm.contentInfo.getGenreToStr(1))),_c('span',{staticClass:"txt_bar"}),(_vm.$isKo)?[_vm._v(" "+_vm._s(_vm.purchaseInfoLabel)),_c('span',{staticClass:"txt_bar"})]:_vm._e(),_c('span',{staticClass:"txt_viewer"},[_c('span',{staticClass:"ico_viewer"},[_vm._v("Count")]),_vm._v(_vm._s(_vm.numToShortForm(_vm.totalViews, _vm.$locale)))])],2),_c('strong',{staticClass:"tit_product",attrs:{"title":_vm.contentInfo.name}},[_vm._v(_vm._s(_vm.contentInfo.name))]),_c('dl',{staticClass:"list_writer"},[_c('dt',{staticClass:"blind"},[_vm._v("작가명")]),_c('dd',{attrs:{"title":_vm.contentInfo.getAuthor(3)}},[_vm._v(" "+_vm._s(_vm.contentInfo.getAuthor(3))+" ")])])]),_c('div',{staticClass:"add_product"},[_c('icon-type-anchor',{attrs:{"label":_vm.lastReadId
            ? _vm.$t('continue_lasttime')
            : _vm.$t('start_from_the_beginning'),"clsName":_vm.TYPE_ICONS.ICON_FIRST.clsName},on:{"onIconClick":_vm.sendGotoChapter}}),_c('button',{staticClass:"btn_info",attrs:{"type":"button"},on:{"click":_vm.sendOpen}},[_vm._v(" "+_vm._s(_vm.$t('content_description'))+" "),_c('icon-type-anchor',{attrs:{"label":_vm.$t(_vm.showDetail.type),"clsName":_vm.showDetail.clsName},on:{"onIconClick":_vm.sendOpen}})],1),_c('a',{staticClass:"link_share",on:{"click":_vm.sendShare}},[_c('icon-type-span',{attrs:{"label":_vm.$t(_vm.TYPE_ICONS.ICON_SHARE.type)},on:{"emitIconClick":_vm.sendShare}})],1),_c('button',{staticClass:"btn_subscribe",class:{ on: _vm.isSubscribed },attrs:{"type":"button"},on:{"click":_vm.sendSubscribed}},[_c('icon-type-span',{attrs:{"label":_vm.isSubscribed
              ? _vm.$t('removed_from_subscribed_list')
              : _vm.$t('added_to_subscribed_list')}})],1)],1),(_vm.mapContentType === 'key_visual')?[(_vm.contentInfo.getKeyVisual())?_c('img',{staticClass:"img_keyvisual",attrs:{"src":_vm.contentInfo.getKeyVisual(),"alt":_vm.contentInfo.name}}):_vm._e()]:_vm._e(),(_vm.mapContentType === 'cover')?[_c('div',{staticClass:"thumb_comic thumb_keyvisual"},[(_vm.contentInfo.getThumbnail('cover'))?_c('img',{staticClass:"img_thumb",attrs:{"src":_vm.contentInfo.getThumbnail('cover'),"alt":_vm.contentInfo.name}}):_vm._e()])]:_vm._e()],2),_c('contents-detail',{attrs:{"contentInfo":_vm.content,"showDetail":_vm.showDetail,"introTheme":_vm.introTheme,"recommends":_vm.recommends},on:{"emitGotoGenre":_vm.sendGotoGenre}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }