

























import {
  ref,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount
} from '@vue/composition-api';
import GlobalFooter from '@/components/organisms/footers/GlobalFooter.vue';

export default defineComponent({
  name: 'Container',
  components: { GlobalFooter },
  setup() {
    const bodyClasses = ref('wrap_notice');
    onBeforeMount(() => {
      document.body.classList.add(bodyClasses.value);
    });
    onBeforeUnmount(() => {
      document.body.classList.remove(bodyClasses.value);
    });

    return {};
  }
});
