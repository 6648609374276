// eslint-disable-next-line

import { Activity } from "@/models";

declare const SingularConfig;
declare const singularSdk;

export class SingularSdk {

	CONTENT_VIEW: string = 'Content_view';
	ADD_TO_FAVORITE: string = 'add to favotie';
	REGISTRATION: string = 'registration';
	INITIAL_REVENUE: string = 'initial revenue';
	REVENUE: string = 'revenue';
	COIN_USED: string = 'Coin_Used';
	NOVEL_VISIT: string = 'novel_visit';

	_singularConfig;

	singular_sdk_info = {
		apiKey: 'nhnentertainment_5e020fb1',
		secretKey: 'dc1f8895c3731a9680bb5f7329dfd544',
		productId: process.env.VUE_APP_SINGULAR_PRODUCTID
	}

	constructor() {
		const { apiKey, secretKey, productId } = this.singular_sdk_info;
		this._singularConfig = new SingularConfig(apiKey, secretKey, productId);
		this.init();
	}

	init() {
		singularSdk.init(this._singularConfig);
	}

	setUser(loginedUserId: number) {
		this._singularConfig.withCustomUserId(loginedUserId);
	}

	event(eventName: string, params?: any) {
		if(this.validateEventName(eventName)) {
			// console.log(`SingularSdkService.event.validateEventName`);
			return;
		}
		if(params) {
			singularSdk.event(eventName, params);
		} else {
			singularSdk.event(eventName);
		}
	}

	/**
	 * 매출
	 * @param {string} eventName
	 * 서드 파티 파트너 및 외부 분석 솔루션과의 호환성을 위해 이벤트 이름과 속성에는 영어를 사용하세요.
	 * 이벤트 이름은 32자 이하의 ASCII 캐릭터로 제한됩니다.
	 * ASCII 캐릭터가 아닌 문자열은 UTF-8로 변환되었을 때 32 byte 미만이어야 합니다.
	 * 속성과 값은 500자 이하의 ASCII 캐릭터로 제한됩니다.
	 * @param {number} amount
	 * @param {any|undefined} options
	 */
	revenue(eventName: string, currency: string, amount: number, options?): void {
		try {
			if(this.validateEventName(eventName)) {
				return;
			}
			if(options) {
				singularSdk.revenue(eventName, currency, amount, options);
			} else {
				singularSdk.revenue(eventName, currency, amount);
			}
		} catch(e) {
			console.log(`SingularSdkService.revenue: ${e}`);
		}
	}

	novelVisit(params?) {
		this.event(this.NOVEL_VISIT, params);
	}

	coinUsed(param?) {
		this.event(this.COIN_USED, param);
	}

	/**
	 * 웹과 앱 간 연결을 빌드하고 설치된 경우 앱을 엽니다.
	 * 앱이 설치되어 있지 않으면 사용자는 사전 정의 된 대체 페이지로 리디렉션됩니다.
	 * @param baseLink
	 * @param deepLink
	 * @param passThrough
	 * @param deferredDeepLink
	 */
	openApp(baseLink: string, deepLink, passThrough, deferredDeepLink) {
		singularSdk.openApp(baseLink, deepLink, passThrough, deferredDeepLink);
	}

	pageVisit() {
		singularSdk.pageVisit();
	}

	login(login_id: number) {
		this.setUser(login_id);
		singularSdk.login(login_id);
	}

	logout() {
		return new Promise<void>(resolve => {
			singularSdk.logout();
			setTimeout(() => {
				resolve();
			}, 500)
		});
		// this.event(this.LOGOUT);
	}

	registration() {
		this.event(this.REGISTRATION);
	}

	/**
	 * 32 byte 체크
	 * @param {string} eventName
	 * @returns {boolean}
	 */
	private validateEventName(eventName: string) {
		return !(this.byteCount(eventName) <= 32);
	}

	/**
	 * byte 체크
	 * @param str
	 */
	private byteCount(str) {
		return encodeURI(str).split(/%..|./).length - 1;
	}

	contentView({
		contentId,
		chapterId,
		title
	}: { contentId: number; chapterId: number; title: string },
		activity: Activity | undefined) {
		if(!activity) return;
		const { rented, unlocked } = activity;
		const param = {
			comicId: contentId,
			chapterId,
			title,
			view_method: this.getViewMethod({ rented, unlocked })
		};
		this.event(this.CONTENT_VIEW, param);
	}

	getViewMethod({ rented, unlocked }: { rented: boolean | undefined, unlocked: boolean | undefined }) {
		if(rented && !unlocked) {
			// rental
			return 'R';
		} else if(!rented && unlocked) {
			// Purchase
			return 'P';
		} else {
			// Free
			return 'F';
		}
	}

	addToFavorite(comicId: number) {
		this.event(this.ADD_TO_FAVORITE, comicId);
	}

	showBanner(){
		singularSdk.showBanner();
	}
}