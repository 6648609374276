
export class Popup {
  id: number;
  area: 'home_popup' | 'content_page_popup' | 'content_page_popup_from_content_viewer';
  page: 'none' | 'home' | 'content_page' | 'content_viewer';
  uiType: 'image' | 'text' | 'html' | 'content';

  category?: 'free' | 'notice' | 'event';
  title: string;
  description?: string;
  themeColor?: string;
  htmlUrl?: string;
  imgUrl: string;
  width?: number;
  height?: number;
  repeatInterval?: number;
  appLinkUrl?: string;
  webLinkUrl?: string
  repeatable: boolean;

  constructor(props: Popup) {
    this.id = props.id;
    this.area = props.area;
    this.page = props.page;
    this.uiType = props.uiType;
    this.category = props.category;
    this.title = props.title || '';
    this.description = props.description;
    this.themeColor = props.themeColor;
    this.htmlUrl = props.htmlUrl;
    this.imgUrl = props.imgUrl || '';
    this.width = props.width;
    this.height = props.height;
    this.appLinkUrl = props.appLinkUrl;
    this.webLinkUrl = props.webLinkUrl;
    this.repeatInterval = props.repeatInterval;
    this.repeatable = props.repeatable;
  }

  isEqualPopup(repeatable: boolean, repeatInterval?: number): boolean {
    if (this.repeatable === repeatable) {
      if (this.repeatInterval === repeatInterval) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}

export class Element {
  id = 0;
  title?: string;
  additionalText?: string;
  badge?: { type?: string; label?: string };
  status?: string;
  original?: boolean;
  gaugeUsable?: boolean;
  ticketUsable?: boolean;
  coinUsable?: boolean;
  totalViews?: number;
  themeColor?: string;
  imageUrl?: string;
  width?: number;
  height?: number;
  largeScreenImageUrl?: string;
  largeScreenWidth?: number;
  largeScreenHeight?: number;
  label?: string;
  urlScheme?: string;
  urlHttp?: string;
  exclusive?: boolean;
  rank?: number;
  constructor(props: Element) {
    this.id = props.id;
    this.title = props.title;
    this.additionalText = props.additionalText;;
    this.badge = props.badge;
    this.status = props.status;
    this.original = props.original;
    this.gaugeUsable = props.gaugeUsable;
    this.ticketUsable = props.ticketUsable;
    this.coinUsable = props.coinUsable;
    this.totalViews = props.totalViews;
    this.themeColor = props.themeColor;
    this.imageUrl = props.imageUrl;
    this.width = props.width;
    this.height = props.height;
    this.largeScreenImageUrl = props.largeScreenImageUrl;
    this.largeScreenWidth = props.largeScreenWidth;
    this.largeScreenHeight = props.largeScreenHeight;
    this.label = props.label;
    this.urlScheme = props.urlScheme;
    this.urlHttp = props.urlHttp;
    this.exclusive = props.exclusive;
  }
  isContentElement(): boolean {
    return this.exclusive !== undefined ? true : false;
  }
}

export class Section {
  id?: number;
  sectionUiType?: 'normal' | 'ranking';
  elementUiType?: 'content_basic' | 'content_big' | 'banner_list' | 'genre_basic' | 'banner_image';
  title?: string;
  additionalText?: string;
  urlScheme?: string;
  urlHttp?: string;
  elements: Element[] = [];
  constructor(props: Section) {
    this.id = props.id;
    this.sectionUiType = props.sectionUiType;
    this.elementUiType = props.elementUiType;
    this.title = props.title;
    this.additionalText = props.additionalText;
    this.urlScheme = props.urlScheme;
    this.urlHttp = props.urlHttp;

    if (this.sectionUiType) {
      this.elements = props.elements ? props.elements.map((node, index) => {
        const el = new Element(node);
        el.rank = (index + 1);
        return el;
      }) : [];
    } else {
      this.elements = props.elements ? props.elements.map(node => new Element(node)) : [];
    }
  }
  hasElements() {
    return this.elements && this.elements.length > 0;
  }
}

export class TopBanner {
  id: number;
  sectionUiType: string;
  elementUiType: string;
  title: string;
  additionalText?: string;
  elements: Element[];

  constructor(props: TopBanner) {
    this.id = props.id;
    this.sectionUiType = props.sectionUiType;
    this.elementUiType = props.elementUiType;
    this.title = props.title;
    this.additionalText = props.additionalText;
    this.elements = props.elements ? props.elements.map(node => new Element(node)).slice(0, 10) : [];
  }
}

export interface MenuHolder {
  code: string;
  title: string;
}
export interface MenuItem {
  code: string;
  label: string;
  mature: boolean;
  sort: number;
  index: number;
  urlHttp?: string;
  urlScheme?: string;
  apiPath: string;
}
export class Menu {
  holder: MenuHolder;
  items: Array<MenuItem> = [];
  constructor(props: Menu) {
    this.holder = props.holder;
    this.items = props.items.map((node, index) => {
      node.index = index;
      return node;
    });
  }
  getHolder(): MenuHolder {
    return this.holder;
  }
  getItem(code: string): MenuItem | undefined {
    return this.items
      .filter(node => {
        return node.code === code;
      })
      .shift();
  }
  getItems(): MenuItem[] {
    return this.items;
  }
}
export class HomeInfo {
  popups: Popup[];
  sections: Section[];
  topBanner: TopBanner | undefined;
  menu: Menu;
  constructor(props: HomeInfo) {
    this.popups = props.popups ? props.popups.map(node => new Popup(node)) : [];
    this.sections = props.sections ? props.sections.map(node => new Section(node)) : [];
    this.topBanner = props.topBanner ? new TopBanner(props.topBanner) : undefined;
    this.menu = props.menu ? new Menu(props.menu) : {
      items: [] as MenuItem[]
    } as Menu;
  }
}
