import { CONTENT_TYPES } from '@/commons/constants/content.types';
import { HomeInfo } from '@/models';
import { createAxiosService } from '@/services/common/create-axios.service';

const axiosService = createAxiosService();

/**
 * home api
 * @param auth /{contentCategory}/home
 */
// const home = (category: string) => {
//   const url = `/${category}/home`;
//   return axiosService.get(url);
// };

// const HomeService = {
//   home
// };

// export { HomeService };

export const getHomeContent = (contentCategory: string) => {
  return new Promise<HomeInfo>(resolve => {
    const url = `/${contentCategory}/home`;
    return axiosService
      .get(url)
      .then(res => {
        const { data } = res;
        resolve(data.data);
      })
  });
};
export const getAdultHomeContent = (apiPath: string) => {
  return new Promise<HomeInfo>(resolve => {
    const url = apiPath;
    return axiosService
      .get(url)
      .then(res => {
        const { data } = res;
        resolve(data.data);
      })
  });
};

const KEY_CONTENT_CATEGORY = 'content_category';

export const setContentCategory = (value: string) => {
  sessionStorage.setItem(KEY_CONTENT_CATEGORY, value);
}
export const getContentCategory = () => {
  setContentCategory(CONTENT_TYPES.all_comic);
  return CONTENT_TYPES.all_comic;
}

export const HomeService = {
  getHomeContent,
  getAdultHomeContent,
  setContentCategory,
  getContentCategory
}