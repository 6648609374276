import { HttpResponse } from '@/commons/constants/http.types';
import { Page } from '@/models';
import { Comment } from '@/models/comment-info';
import { createAxiosService } from '@/services/common/create-axios.service';
import { AxiosResponse } from 'axios';

const axiosService = createAxiosService();

export interface AuthorCommentInterface {
  userId: number;
  epochSecond?: number | null;
}
const getAuthorComment = async (params: AuthorCommentInterface) => {
  const { userId, epochSecond } = params;
  const url = `comment/history/author/${userId}${epochSecond ? '?lastRespondedPostedSeconds=' + epochSecond : ''
    }`;

  const {
    data: { data }
  } = await axiosService.get(url).then((res) => res);
  return data;
};

export const getChapterComment = (
  options: {
    contentType: string;
    contentId: number;
    chapterId: number;
    orderType: 'likes' | 'recent';
  },
  lastRespondedSeqNo?: string
) => {
  const { contentType, contentId, chapterId, orderType } = options;
  let url = `/${contentType}/${contentId}/chapter/${chapterId}/comment/${orderType}`;
  if(lastRespondedSeqNo !== undefined) {
    url += `?lastRespondedSeqNo=${lastRespondedSeqNo}`;
  }
  return new Promise<{ comments: Comment[]; page?: Page }>((resolve) => {
    return axiosService
      .get(url)
      .then((res) => {
        const { data } = res;
        resolve(data.data);
      });
  });
};

export const saveComment = (
  {
    contentType,
    contentId,
    chapterId
  }: {
    contentType: string;
    contentId: number;
    chapterId: number;
  },
  comment: string
) => {
  const url = `/${contentType}/${contentId}/chapter/${chapterId}/comment`;
  return new Promise<void>((resolve, reject) => {
    const bodyFormData = new FormData();
    bodyFormData.append('comment', comment);
    return axiosService.post(url, bodyFormData).then((res: AxiosResponse) => {
      const result: HttpResponse = res.data;
      if(result.result.code < 300) {
        resolve();
      } else {
        reject(result.result);
      }
    });
  });
};

export const deleteComment = ({
  contentType,
  commentId
}: {
  contentType: string;
  commentId: number;
}) => {
  const url = `/${contentType}/comment/${commentId}`;
  return new Promise<void>((resolve) => {
    return axiosService.delete(url).then((res) => {
      const {
        data: { result }
      } = res;
      resolve(result);
    });
  });
};

export const likeComment = ({
  contentType,
  commentId
}: {
  contentType: string;
  commentId: number;
}) => {
  const url = `/${contentType}/comment/${commentId}/like`;
  return new Promise<any>((resolve, reject) => {
    return axiosService.post(url).then((res) => {
      const { data: { result } } = res
      if(result.code === 200) {
        resolve(result);
      } else {
        reject();
      }
    });
  });
};

export const reportComment = ({
  contentType,
  commentId,
  category,
  remarksDetail
}: {
  contentType: string;
  commentId: number;
  category: string;
  remarksDetail?: string;
}) => {
  const url = `/${contentType}/comment/${commentId}/report/${category}`;
  const params = new URLSearchParams();
  params.append('remarksDetail', remarksDetail ? remarksDetail : '');
  return new Promise<number>((resolve, reject) => {
    return axiosService.post(url, params).then((res) => {
      const result: HttpResponse = res.data;
      if(result.result.code <= 299) {
        resolve(result.result.code);
      } else {
        reject(result.result.code);
      }
    });
  });
};

const getCommentHistory = async (epochSecond?: number) => {
  const url = `comment/history${epochSecond ? '?lastRespondedPostedSeconds=' + epochSecond : ''
    }`;
  const {
    data: { data }
  } = await axiosService.get(url).then((res) => res);
  return data;
};


export const commentApi = {
  getAuthorComment,
  getChapterComment,
  saveComment,
  deleteComment,
  likeComment,
  reportComment,
  getCommentHistory
};
