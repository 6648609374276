import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import VueRouter from 'vue-router';
import router from '@/router/index';
import { i18n } from '@/commons/i18n/index';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import * as rules from '@/helpers/validator';
import store from '@/store/index';
import VueCompositionApi from '@vue/composition-api';
import PortalVue from 'portal-vue';
import './quasar';
import '@/assets/css/viewer/css-loader.css';
import '@/assets/css/viewer/swiper.4.4.6.min.css';
import '@/assets/css/commons.scss';
import '@/assets/css/responsive.css';
import '@/assets/css/custom.css';
import '@/assets/css/magazine_comic.css';
import '@/assets/css/codemirror.min.css';
import '@/assets/css/toastui-editor.min.css';
// import '@/assets/css/challenge/challenge.scss';

/**
 * reference: https://github.com/nicolasbeauvais/vue-social-sharing
 */
import SocialSharing from 'vue-social-sharing';
import { CustomPlugin } from './plugins/CustomPlugin';

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(VueRouter);
Vue.use(VueCompositionApi);
Vue.use(PortalVue);
Vue.use(SocialSharing);
Vue.use(CustomPlugin);
Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
