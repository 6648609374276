export interface IconType {
	type: string;
	clsName?: string;
	text?: string;
}

/**
 * 헤더 - 검색
 */
export const ICON_SEARCH: IconType = {
	type: 'search',
	clsName: 'link_search',
	text: '검색'
};

/**
 * 헤더 - 선물함
 */
export const ICON_INBOX: IconType = {
	type: 'inbox',
	clsName: 'link_inbox',
	text: '선물함'
};

/**
 * 헤더  사이드메뉴
 */
export const ICON_SIDEMENU: IconType = {
	type: 'sidemenu',
	clsName: 'link_sidemenu',
	text: '사이드메뉴'
};

/**
 * 헤더 - 내서재
 */
export const ICON_LIBRARY: IconType = {
	type: 'library',
	clsName: 'link_library',
	text: '내서재'
};

/**
 * 헤더 - 내정보
 */
export const ICON_ACCOUNT: IconType = {
	type: 'mypage',
	clsName: 'link_account',
	text: '내정보'
};

/**
 * next
 */
export const ICON_NEXT: IconType = {
	type: 'next',
	clsName: 'btn_next',
	text: '다음'
};

/**
 * prev
 */
export const ICON_PREV: IconType = {
	type: 'prev',
	clsName: 'btn_prev',
	text: '이전'
};

/**
 * 뒤로가기
 */
export const ICON_BACK: IconType = {
	type: 'go_back',
	clsName: 'link_back',
	text: '뒤로가기'
};

/**
 * 더보기
 */
export const ICON_VIEWMORE: IconType = {
	type: 'view_more',
	clsName: 'link_more',
	text: '더보기'
};

/**
 * 첫화보기
 */
export const ICON_FIRST: IconType = {
	type: 'first_reading',
	clsName: 'link_first',
	text: '첫화보기'
};

/**
 * 작품정보버튼 열기
 */
export const ICON_OPEN: IconType = {
	type: 'open',
	clsName: 'ico_fold close',
	text: '열기'
};

/**
 * 작품정보버튼 닫기
 */
export const ICON_CLOSE: IconType = {
	type: 'close',
	clsName: 'ico_fold',
	text: '닫기'
};

/**
 * 공유하기
 */
export const ICON_SHARE: IconType = {
	type: 'share',
	text: '공유하기'
};

/**
 * 할인
 */
export const ICON_DISCOUNT: IconType = {
	type: 'DISCOUNT',
	clsName: 'ico_news ico_sale',
	text: '할인'
};

/**
 * 코인백
 */
export const ICON_COIN_BACK: IconType = {
	type: 'COIN_BACK',
	clsName: 'ico_news ico_coinback',
	text: '코인백'
};

/**
 * 당신에게만 무료
 */
export const ICON_EVENT_FREE: IconType = {
	type: 'EVENT_FREE',
	clsName: 'ico_news ico_free'
};

/**
 * notice
 */
export const ICON_NOTICE: IconType = {
	type: 'notice',
	clsName: 'ico_news ico_notice'
};

/**
 * 정렬(첫편부터, 최신화부터)
 */
export const ICON_SORT: IconType = {
	type: 'sorting',
	clsName: 'ico_sort'
};

/**
 * 상품안내
 */
export const ICON_PRODUCT_HELP: IconType = {
	type: 'product_information',
	clsName: 'link_help'
};

/**
 * 선택구매
 */
export const ICON_SELECT_PURCHASE: IconType = {
	type: 'unlock_all',
	clsName: 'link_purchase',
	text: '선택구매'
};

export const ICON_WAITFREE: IconType = {
	type: 'read_for_free',
	clsName: 'ico_wait',
};

/**
 * 작품목록 돌아가기
 */
export const ICON_BACK_CONTENTS_HOME: IconType = {
	type: 'back_contents_list',
	clsName: 'ico_control'
};


/**
 * 최근 본
 */
 export const ICON_NOW: IconType = {
	type: 'now',
	clsName: 'ico_now',
	text: '최근 본'
};

export const ICON_RENTAL_ONLY: IconType = {
	type: 'rental_only',
	clsName: 'txt_rent'
};

export const TYPE_ICONS = {
	ICON_SEARCH,
	ICON_INBOX,
	ICON_SIDEMENU,
	ICON_LIBRARY,
	ICON_ACCOUNT,
	ICON_NEXT,
	ICON_PREV,
	ICON_BACK,
	ICON_VIEWMORE,
	ICON_FIRST,
	ICON_OPEN,
	ICON_CLOSE,
	ICON_SHARE,
	ICON_DISCOUNT,
	ICON_COIN_BACK,
	ICON_EVENT_FREE,
	ICON_SORT,
	ICON_PRODUCT_HELP,
	ICON_SELECT_PURCHASE,
	ICON_WAITFREE,
	ICON_BACK_CONTENTS_HOME,
	ICON_RENTAL_ONLY,
	ICON_NOTICE,
	ICON_NOW
};