
















import {
  computed,
  defineComponent,
  onUnmounted,
  ref
} from '@vue/composition-api';
import store from '@/store';
import router from '@/router';
import { MenuItem } from '@/models';
import { sendNclick } from '@/services';

export default defineComponent({
  name: 'HomeMenuItems',
  props: {
    items: Array
  },
  setup() {
    const { getters } = store;
    const isFixed = ref(false);
    const contentCategory = computed(
      () => getters['HomeStore/contentCategory']
    );
    const topslideHeight = computed(() => getters['UIStore/topslideHeight']);
    const headerHeight = computed(() => getters['UIStore/headerHeight']);

    const onMenuClick = async (menuItem: MenuItem) => {
      const { apiPath } = menuItem;
      if (apiPath) {
        sendNclick('home', 'home.menu', '', '', menuItem.code);
        router.push(apiPath).catch(() => {
          // same route
        });
      }
    };

    const handleScroll = () => {
      const bodyElem = document.body;
      const bodyTop = Math.abs(+bodyElem.style.top.replace('px', ''));
      const openDialog = bodyElem.classList.contains('q-body--prevent-scroll');
      const scrollMain = topslideHeight.value - headerHeight.value;
      isFixed.value =
        window.scrollY > scrollMain || (bodyTop > scrollMain && openDialog);
    };

    window.addEventListener('scroll', handleScroll);

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      contentCategory,
      isFixed,
      onMenuClick
    };
  }
});
