





























import {
  computed,
  ComputedRef,
  defineComponent,
  Ref,
  ref,
  watch
} from '@vue/composition-api';
import store from '@/store/index';
import BasicContentCard from '@/components/molecules/contents-card/BasicContentCard.vue';
import RankingContentCard from '@/components/molecules/contents-card/RankingContentCard.vue';
import NoDataGuide from '@/components/molecules/contents/NoDataGuide.vue';
import { CONTENT_CARD_TYPE } from '@/commons/constants/content.types';
import { ContentInfo } from '@/models';

export default defineComponent({
  name: 'ContentListModule',
  components: {
    BasicContentCard,
    RankingContentCard,
    NoDataGuide
  },
  props: {
    contentList: Array,
    contentCardType: String,
    listType: Array,
    label: String,
    cacheKey: String,
    menuHolder: Object
  },
  setup(props) {
    const { getters } = store;
    const list = computed(() => (props.contentList ? props.contentList : []));
    const listClsType: ComputedRef<string[]> = computed(() =>
      props.listType ? (props.listType as string[]) : ['LIST', 'LIST']
    );
    const isWide: ComputedRef<boolean> = computed(
      () => getters['UIStore/isWide']
    );
    const LIST_CLS_NAME: { LIST: string; GRID: string } = {
      LIST: 'list_comic',
      GRID: 'list_thumb'
    };
    const listClsName: Ref<string> = ref(
      LIST_CLS_NAME[listClsType.value[isWide.value ? 1 : 0]]
    );
    const createUniqueKey = (content: ContentInfo) =>
      `${props.cacheKey ? props.cacheKey : ''}_${content.type}_${content.id}`;

    watch(isWide, (data) => {
      const currentListType: string = listClsType.value[data ? 1 : 0];
      listClsName.value = LIST_CLS_NAME[currentListType];
    });

    watch(listClsType, (data) => {
      const currentListType: string = data[isWide.value ? 1 : 0];
      listClsName.value = LIST_CLS_NAME[currentListType];
    });

    return {
      createUniqueKey,
      list,
      CONTENT_CARD_TYPE,
      listClsName
    };
  }
});
