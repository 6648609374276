

































































import {
  computed,
  ComputedRef,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch
} from '@vue/composition-api';
import Store from '@/store/index';
import router from '@/router/index';
import NormalThumbnail from '@/components/molecules/contents-elements/NormalThumbnail.vue';
import BigThumbnail from '@/components/molecules/contents-elements/BigThumbnail.vue';
import GenreThumbnail from '@/components/molecules/contents-elements/GenreThumbnail.vue';
import SectionNavigator from '@/components/molecules/navigators/SectionNavigator.vue';
import H from '@/components/atoms/templates/H.vue';
import IconTypeAnchor from '@/components/atoms/icons/IconTypeAnchor.vue';
import {
  gotoChapter,
  gotoContentHome,
  gotoLinkPage
} from '@/services/routing/routing.helper';
import {
  nextBtnState,
  SectionHelperService
} from '@/services/home/section.helper';
import {
  separateUrl,
  getContentChapterPath
} from '@/services/contents/contents.helper';
import { IconType, ICON_VIEWMORE } from '@/commons/constants/icon-types';
import { Element, Section } from '@/models';
import { sendNclick } from '@/services';

export default defineComponent({
  name: 'NormalSection',
  components: {
    NormalThumbnail,
    BigThumbnail,
    GenreThumbnail,
    SectionNavigator,
    H,
    IconTypeAnchor
  },
  props: {
    section: Object,
    maxLen: Number,
    hideMore: Boolean
  },
  setup(props) {
    const { getters } = Store;
    const sectionInfo: ComputedRef<Section> = computed(
      () => props.section as Section
    );
    const maxLen: ComputedRef<number | undefined> = computed(
      () => props.maxLen
    );
    const elements: ComputedRef<Element[]> = computed(() => {
      const sliceSections = maxLen.value
        ? sectionInfo.value.elements.slice(0, maxLen.value)
        : sectionInfo.value.elements;
      return sectionInfo.value && sectionInfo.value.elements
        ? sliceSections
        : [];
    });
    const elementUiType: ComputedRef<
      | 'content_basic'
      | 'content_big'
      | 'banner_list'
      | 'genre_basic'
      | 'banner_image'
      | undefined
    > = computed(() => {
      return sectionInfo.value && sectionInfo.value.elementUiType
        ? sectionInfo.value.elementUiType
        : undefined;
    });
    const MAX_SLIDE_LEN = SectionHelperService.getSectionMaxLen(
      elementUiType.value
    );
    const isWide: ComputedRef<boolean> = computed(
      () => getters['UIStore/isWide']
    );
    const ulRef: any = ref(null);

    let liWidth = SectionHelperService.getLiWidth(
      sectionInfo.value.elementUiType
    );
    let currentIndex = 1;
    let currentX = 0;
    let value = Math.floor(elements.value.length / MAX_SLIDE_LEN);
    let namoji = Math.floor(elements.value.length % MAX_SLIDE_LEN);
    const prev = ref(false);
    const next = ref(nextBtnState(value, namoji));

    const getSectionElementClsNames = (elementUiType: string | undefined) => {
      switch (elementUiType) {
        case 'content_big':
          return {
            sectionName: 'list_slide_big',
            slidOptName: '',
            listSlideName: 'list_slide'
          };
        case 'genre_basic':
          return {
            sectionName: 'section_genre',
            slidOptName: '',
            listSlideName: 'list_genre'
          };
        case 'banner_list':
          return {
            sectionName: '',
            slidOptName: 'slide_comic_event',
            listSlideName: 'list_slide list_event'
          };
        case 'content_basic':
        default:
          return {
            sectionName: '',
            slidOptName: '',
            listSlideName: 'list_slide'
          };
      }
    };

    const clsNames = getSectionElementClsNames(elementUiType.value);

    const sendContents = (element: Element) => {
      if (!element.urlHttp) return;
      const str = separateUrl(element.urlHttp);
      const result = getContentChapterPath(str);
      if (!result) return;
      if (result.chapterId) {
        sendNclickContent(result.chapterId);
        gotoChapter(result);
      } else {
        sendNclickContent(element.id);
        gotoContentHome(result);
      }
    };

    const sendNclickContent = (contentId: number) => {
      sendNclick(
        'home',
        'home.card',
        '' + contentId,
        '',
        sectionInfo.value.elementUiType +
          '&sectionid=' +
          sectionInfo.value.id +
          '&id=' +
          contentId
      );
    };

    const sendGenre = (element: Element) => {
      if (!element.urlHttp) return;
      const str = separateUrl(element.urlHttp);
      sendNclickContent(element.id);
      router.push(`/${str[1]}/${str[2]}/${str[3]}`);
    };

    const resetIndex = () => {
      try {
        currentIndex = 1;
        currentX = 0;
        value = Math.floor(elements.value.length / MAX_SLIDE_LEN);
        namoji = Math.floor(elements.value.length % MAX_SLIDE_LEN);
        prev.value = false;
        next.value = nextBtnState(value, namoji);
        liWidth = SectionHelperService.getLiWidth(
          sectionInfo.value.elementUiType
        );
        ulRef.value.style.transform = `translateX(0px)`;
      } catch (e) {
        //
      }
    };

    const sendDirection = ({ type }: IconType) => {
      if (type.toLowerCase() === 'prev') {
        gotoPrev();
      } else {
        gotoNext();
      }
    };

    const gotoPrev = () => {
      if (currentIndex > 1) {
        currentX = currentX - MAX_SLIDE_LEN * liWidth;
        ulRef.value.style.transform = `translateX(-${currentX}px)`;
        currentIndex = currentIndex - 1;
        next.value = true;
        prev.value = currentX !== 0;
      } else {
        if (namoji > 0) {
          currentX = currentX - namoji * liWidth;
          ulRef.value.style.transform = `translateX(-${currentX}px)`;
          next.value = true;
        }
        prev.value = false;
      }
    };

    const gotoNext = () => {
      if (currentIndex === value) {
        if (namoji > 0) {
          currentX = currentX + namoji * liWidth;
          ulRef.value.style.transform = `translateX(-${currentX}px)`;
          prev.value = true;
        }
        next.value = false;
      } else {
        currentIndex = currentIndex + 1;
        currentX = currentX + MAX_SLIDE_LEN * liWidth;
        ulRef.value.style.transform = `translateX(-${currentX}px)`;
        prev.value = true;
        next.value = !(namoji === 0 && currentIndex === value);
      }
    };

    const sendViewMore = () => {
      if (!sectionInfo.value.urlHttp) return;
      sendNclick(
        'home',
        'home.more',
        '',
        '',
        sectionInfo.value.elementUiType + '&sectionid=' + sectionInfo.value.id
      );
      const str = separateUrl(sectionInfo.value.urlHttp);
      gotoLinkPage(`/${str.join('/')}`);
    };

    watch(elements, () => {
      resetIndex();
    });

    return {
      sectionInfo,
      elements,
      elementUiType,
      isWide,
      MAX_SLIDE_LEN,
      ICON_VIEWMORE,
      ulRef,
      prev,
      next,
      sendViewMore,
      sendContents,
      sendGenre,
      sendDirection,
      ...toRefs(reactive(clsNames))
    };
  }
});
