



























import {
  ref,
  computed,
  watch,
  onUnmounted,
  onBeforeMount,
  defineComponent
} from '@vue/composition-api';
import Store from '@/store/index';
import router from '@/router/index';
import { ERROR_CODES } from '@/commons/constants/error-codes';

/**
 * API 서비스 오류, 네트워크 오류 등
 */
export default defineComponent({
  name: 'ErrorDialog',
  setup(props, { root }) {
    const { getters, dispatch, commit } = Store;
    const isAlert = ref(false);
    const route = root.$route;
    const errorCode = computed(() => getters['ErrorStore/errorCode']);
    const errorState = computed(() => getters['ErrorStore/errorState']);
    const isErrorPop = computed(() => getters['ErrorStore/isErrorPop']);

    const dismiss = () => {
      const code = errorCode.value;
      dispatch('ErrorStore/RESET_STATE');
      isAlert.value = false;
      if (!root.$route.name && code === ERROR_CODES.CODE_303) {
        window.location.reload();
      }
    };

    const btnLogin = () => {
      dismiss();
      router.push({ name: 'Login', query: { redirect: route.path } });
    };

    onBeforeMount(() => {
      dismiss();
    });

    onUnmounted(() => {
      dismiss();
    });

    const returnCase = (severMsg: string, returnMsg: string): boolean => {
      return errorState.value.message === returnMsg;
    };

    watch(isErrorPop, (data) => {
      if (data) {
        // nextPage가 유효할 경우 팝업 패스
        if (errorState.value.nextPage) return dismiss();
        // Invalid request parameter for display
        if (returnCase(errorState.value.message, 'Invalid request hash token'))
          return dismiss();

        // 503 Maintenance 서비스공지
        switch (errorCode.value) {
          case ERROR_CODES.CODE_503:
            router.push({
              name: 'SystemInspection',
              params: {
                errorTitle: errorState.value.title,
                errorMessage: errorState.value.message,
                errorEndAt: errorState.value.endAt
              }
            });
            break;
          case ERROR_CODES.CODE_500:
            router.push({
              name: 'ServiceError',
              params: { errorState: errorState.value }
            });
            break;
          case ERROR_CODES.CODE_401:
          case ERROR_CODES.CODE_451: {
            const url = errorState.value.response.url;
            const options = {
              name: 'Login'
            };
            if (url && url.length > 0) {
              options['query'] = {
                redirect: url
              };
            }
            commit('AuthStore/logout');
            router.push(options).catch(() => {
              // same url route
            });
            break;
          }
          default:
            isAlert.value = true;
            break;
        }
      }
    });

    watch(
      () => root.$route,
      () => dismiss()
    );

    return {
      isAlert,
      errorCode,
      isErrorPop,
      errorState,
      dismiss,
      btnLogin
    };
  }
});
