
































import {ContentInfo} from '@/models';
import {computed, ComputedRef, defineComponent} from '@vue/composition-api';
import {t} from '@/commons/i18n/index';
import {AUTHOR_TYPE} from '@/commons/constants/author-types';
import ProductInfoItem from './ProductInfoItem.vue';
import router from '@/router';

export default defineComponent({
  name: 'ProductionInfo',
  props: {
    content: ContentInfo
  },
  components: {
    ProductInfoItem
  },
  setup(props) {
    const contentInfo: ComputedRef<ContentInfo> = computed(
      () => props.content as ContentInfo
    );
    const productInfo = computed(() => {
      return {
        author: contentInfo.value.getAuthorRoleSearch(
            AUTHOR_TYPE.AUTHOR_TYPE_CREATOR
        ),
        writer: contentInfo.value.getAuthorRoleSearch(
            AUTHOR_TYPE.AUTHOR_TYPE_WRITER
        ),
        artist: contentInfo.value.getAuthorRoleSearch(
            AUTHOR_TYPE.AUTHOR_TYPE_ARTIST
        ),
        assistant: contentInfo.value.getAuthorRoleSearch(
            AUTHOR_TYPE.AUTHOR_TYPE_ASSISTANT
        ),
        originalCreator: contentInfo.value.getAuthorRoleSearch(
            AUTHOR_TYPE.AUTHOR_TYPE_ORIGINAL_CREATOR
        ),
        studio: contentInfo.value.getAuthorRoleSearch(
            AUTHOR_TYPE.AUTHOR_TYPE_STUDIO
        )
      }
    });

    const searchProductInfo = (type: string, item: string) => {
      const keyword = encodeURIComponent(`${type}:${item}`);
      router.push(`/search/${keyword}`);
    };

    return {
      t,
      AUTHOR_TYPE,
      productInfo,
      searchProductInfo
    };
  }
});
