












































import { computed, defineComponent } from '@vue/composition-api';
import Badge from '@/components/atoms/templates/Badge.vue';
import ComicoOriginal from '@/components/atoms/templates/ComicoOriginal.vue';
import IconThumbnail from '@/components/atoms/templates/IconThumbnail.vue';
import { numToShortForm } from '@/services/common/utils';
import { ICON_WAITFREE } from '@/commons/constants/icon-types';

export default defineComponent({
  name: 'NormalThumbnail',
  components: {
    Badge,
    ComicoOriginal,
    IconThumbnail
  },
  props: {
    element: Object,
    setIndex: Boolean,
    index: Number,
    fromRecomm: Boolean
  },
  setup(props, { emit, root }) {
    const totalViews = computed(() => {
      try {
        const { element } = props;
        return element && element.totalViews
          ? numToShortForm(element.totalViews, root['$locale'])
          : 0;
      } catch (e) {
        return 0;
      }
    });
    const onClick = () => emit('gotoContents', props.element);
    const imagePathCheck = (imgPath?: string) => {
      if (imgPath) return imgPath;
      const tmpImgPath =
        root['$isKo'] || root['$isJa'] ? 'img_thumb' : 'img_thumb_pocket';
      return require('@/assets/img/' + tmpImgPath + '.png');
    };

    const imageLoadErrorCheck = (evt) => {
      evt.target.src = imagePathCheck();
    };
    return {
      ICON_WAITFREE,
      totalViews,
      numToShortForm,
      onClick,
      imagePathCheck,
      imageLoadErrorCheck
    };
  }
});
