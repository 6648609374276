var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.onContentClick($event)}}},[_c('card-thumbnail',{attrs:{"content":_vm.contentInfo}}),(!_vm.onlyThumb)?_c('div',{staticClass:"thumb_info",class:{
      thumb_event: _vm.contentInfo.badge || _vm.contentInfo.status === 'event'
    }},[_c('div',{staticClass:"tit_info",class:{
        tit_info_type:
          _vm.contentInfo.restrictedAge &&
          (_vm.contentInfo.badge ||
            _vm.contentInfo.status === 'event' ||
            _vm.contentInfo.status === 'started' ||
            _vm.contentInfo.status === 'updated' ||
            _vm.contentInfo.status === 'completed')
      }},[(_vm.menuHolder && _vm.menuHolder.code === 'daily')?_c('badge-hiatus',{attrs:{"badge":_vm.contentInfo.badge,"status":_vm.contentInfo.status}}):_vm._e(),(!_vm.completedBadgeCondition)?_c('badge',{attrs:{"badge":_vm.contentInfo.badge,"status":_vm.contentInfo.status}}):_vm._e(),(_vm.$isKo && _vm.contentInfo.restrictedAge)?_c('icon-age',{attrs:{"restrictedAge":_vm.contentInfo.restrictedAge}}):_vm._e(),_c('strong',{staticClass:"tit_comic"},[_vm._v(_vm._s(_vm.contentInfo.name))])],1),_c('span',{staticClass:"txt_info"},[_vm._v(_vm._s(_vm.contentTypeStr)),(_vm.contentTypeStr)?_c('span',{staticClass:"txt_bar"}):_vm._e(),_vm._v(_vm._s(_vm.contentInfo.getGenreToStr()))]),_c('span',{staticClass:"txt_viewer"},[_c('span',{staticClass:"ico_viewer"},[_vm._v("Count")]),_vm._v(_vm._s(_vm.contentInfo.getTotalView(_vm.$locale)))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }