


















import { computed, defineComponent, watch } from '@vue/composition-api';
import Container from '@/views/common/Container.vue';
import { isPC, ClassesHidden } from '@/services/common/utils';

export default defineComponent({
  name: 'NotFound',
  components: {
    Container
  },
  setup() {
    const isMobile = computed(() => !isPC());

    watch(isMobile, (res: boolean) => {
      ClassesHidden(res, 'hidden');
    });
    return {};
  }
});
