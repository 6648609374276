import { AxiosResponse } from 'axios';
import { ERROR_CODES } from '@/commons/constants/error-codes';

export const setReturnYn = (url: string | undefined, value: string): void => {
  if (!url) return;
  sessionStorage.setItem(url, value);
};

export const getReturnYn = (
  apiPath: string | undefined
): string | null | undefined => {
  if (!apiPath) return;
  const isReturn = sessionStorage.getItem(apiPath);
  sessionStorage.removeItem(apiPath);
  return isReturn;
};

const checkResponseUrl = (
  response: AxiosResponse,
  checkType: string
): boolean | undefined =>
  response.config.url?.toLowerCase().includes(checkType);

const findPath = (path: string | undefined, idxStr: string): boolean => {
  return path && path?.indexOf(idxStr) > -1 ? true : false;
};

/** [CKR] 소셜회원 중 휴면계정 예외처리 */
const getExceptionDormantKR = (response: AxiosResponse): boolean => {
  const isIdpMapping = checkResponseUrl(response, 'mapping');
  const isIdpSignin =
    checkResponseUrl(response, 'external-idp') &&
    checkResponseUrl(response, 'signin');
  const isEmailSignin = checkResponseUrl(response, 'signin');

  switch (response.data.result.code) {
    case ERROR_CODES.CODE_417:
      return isIdpSignin || isIdpMapping || false;
    case ERROR_CODES.CODE_410:
      return isEmailSignin || false;
    default:
      return false;
  }
};

export const InspectorHelperService = {
  setReturnYn,
  getReturnYn,
  checkResponseUrl,
  findPath,
  getExceptionDormantKR
};
