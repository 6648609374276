


















import {
  reactive,
  toRefs,
  defineComponent,
  computed
} from '@vue/composition-api';
import Store from '@/store/index';
import { getLanguageCookie } from '@/helpers/cookies';
import ErrorDialog from '@/dialogs/ErrorDialog.vue';
import GlobalHeader from '@/components/organisms/headers/GlobalHeader.vue';
import GlobalFooter from '@/components/organisms/footers/GlobalFooter.vue';
import Dimmed from '@/components/molecules/dimmed/Dimmed.vue';
import { FooterUI } from '@/models';
import { Language } from './commons/constants/enum';
import { SingularSdk } from './services/singluar/singluar';
import { getCurrentUser } from './services';
import { fbq } from './services/common/facebook-pixel.service';
import router from './router';
import { isIPhone } from './services/common/utils';

export default defineComponent({
  name: 'LayoutDefault',
  components: {
    GlobalHeader,
    ErrorDialog,
    GlobalFooter,
    Dimmed
  },
  props: {
    footer: Boolean
  },
  setup(props, { root }) {
    const { dispatch, commit, getters } = Store;
    const footers: FooterUI = reactive({ footerRef: null });
    const language: Language = getLanguageCookie();
    const serviceAppkey = computed(
      () => getters['GlobalStore/getServiceAppkey']
    );
    const isChallenge = computed(
      () => router.currentRoute.fullPath === '/challenge'
    );
    const tweak = () => {
      if (footers.footerRef) {
        const offset = footers.footerRef.$el.clientHeight;
        return {
          minHeight: offset ? `calc(100vh - ${offset}px)` : '100vh'
        };
      }
    };

    const checkWebview = (appType: string): boolean =>
      window.navigator.userAgent.includes(appType);

    const checkserviceAppkey = () => {
      switch (true) {
        case checkWebview('comicoKrApp-one_store'):
          return 'comicoKrApp-one_store';
        case checkWebview('comicoGlobalApp'):
          return 'comicoGlobalApp';
        case checkWebview('comicoKrApp'):
          return 'comicoKrApp';
        case checkWebview('comicoJpApp'):
          return 'comicoJpApp';
        default:
          return null;
      }
    };

    const acceptMatureInit = (): void => {
      if (
        (root['$isKo'] || root['$isJa']) &&
        !sessionStorage.getItem('acceptMature')
      ) {
        sessionStorage.setItem('acceptMature', 'Y');
      }
    };

    const initSingular = () => {
      try {
        const singular = new SingularSdk();
        singular.showBanner();
        const currentUser = getCurrentUser();
        commit('SingularStore/singular', singular);
        if (currentUser) {
          const { authentication } = JSON.parse(currentUser);
          singular.login(authentication.userId);
        }
      } catch (e) {
        //
      }
    };

    const gtmLoginCheck = () => {
      try {
        const currentUser = getCurrentUser();
        window['dataLayer'].push({
          signin_check: currentUser ? 'TRUE' : 'FALSE'
        });
      } catch (e) {
        //
      }
    };

    const checkPlatform = () => {
      let platform = 'Web PC';
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobi|mobi/i.test(navigator.userAgent);
      if (isMobile) {
        platform = 'Web Mobile'
      }
      window['dataLayer'].push({
        event: 'user_device',
        device: platform
      });
    }


    window.addEventListener('resize', () => {
      commit('UIStore/windowWidth', window.innerWidth);
    });

    dispatch('I18nStore/CHANGE_LANGUAGE', language);
    dispatch('GlobalStore/config', 'category');
    dispatch('GlobalStore/config', 'language');

    commit('DialogStore/dialog', root.$q.dialog);
    commit('DialogStore/notify', root.$q.notify);
    commit('GlobalStore/setServiceAppkey', checkserviceAppkey());

    acceptMatureInit();
    initSingular();
    gtmLoginCheck();
    checkPlatform();

    fbq.searchKeyword();

    return { ...toRefs(footers), tweak, serviceAppkey, isChallenge, isIPhone };
  }
});
