









































































import { ref, computed, defineComponent, watch } from '@vue/composition-api';
import router from '@/router';
import Container from '@/views/login/Container.vue';
import Store from '@/store';
import LoginEmail from '@/views/login/LoginEmail.vue';
import { isPC, ClassesHidden } from '@/services/common/utils';
export default defineComponent({
  name: 'Login',
  components: {
    Container,
    LoginEmail
  },
  setup(_props, { root }) {
    const route = root.$route;
    const env = process.env.VUE_APP_LANGUAGE;
    const { redirect } = route.query;
    const { dispatch, getters } = Store;
    const isMobile = computed(() => !isPC());
    const isEmailPage = ref(false);
    const externalIdp = computed(() => getters['SocialLoginStore/externalIdp']);
    const currentRouter = router.currentRoute.query.redirect || '/';
    const showTooltip = ref(false);
    const getExternalIdpUrl = (loginType: string) =>
      dispatch('SocialLoginStore/getExternalIdpUrl', loginType);

    const gotoEmail = () => {
      isEmailPage.value = true;
      router.push({ name: 'LoginEmail', query: { redirect: redirect } });
    };
    const externalIdpConnect = (loginType: string) => {
      sessionStorage.setItem('redirectUrl', currentRouter.toString());
      getExternalIdpUrl(loginType).then((res) => {
        document.location.href = res;
        window['dataLayer'].push({ event: 'signin_complete' });
      });
    };

    watch(isMobile, (res: boolean) => {
      ClassesHidden(res, 'hide');
    });

    const onBack = () => {
      router.back();
    };

    // !externalIdp.value.appleid ? getExternalIdpUrl() : null;

    return {
      env,
      isEmailPage,
      gotoEmail,
      onBack,
      externalIdpConnect,
      showTooltip
    };
  },
  directives: {
    hideTooltip: {
      bind: (el: any, _bind, vnode: any): void => {
        el.clickOutsideEvent = (event) => {
          if (event.target.classList.value !== 'btn_info') {
            vnode.context.showTooltip = false;
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind: (el: any): void => {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
    }
  }
});
