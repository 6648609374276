
























































import { imageCdn } from '@/commons/constants/challenge/challenge-properties';
import { defineComponent } from '@vue/composition-api';
import BannerItem from '@/components/molecules/challenge/BannerItem.vue';

export default defineComponent({
  props: {
    bannerList: Array
  },
  components: {
    BannerItem
  },
  setup(_props) {
    const setImagePath = (item) =>
      `${imageCdn}${item.filePath}${item.fileName}`;

    return { setImagePath };
  }
});
