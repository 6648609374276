





















































import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import router from '@/router/index';
import { Badge, BadgeHiatus } from '@/components/atoms';
import IconAge from '@/components/atoms/icons/IconAge.vue';
import CardThumbnail from '@/components/molecules/contents-elements/CardThumbnail.vue';
import { ContentInfo } from '@/models/content-info';
import { gotoContentHome } from '@/services/routing/routing.helper';

export default defineComponent({
  name: 'TagContentCard',
  components: {
    Badge,
    BadgeHiatus,
    IconAge,
    CardThumbnail
  },
  props: {
    content: ContentInfo,
    rank: Number,
    del: Boolean
  },
  setup(props) {
    const contentType = props.content?.type;

    const isNovel = () => {
      switch (contentType) {
        case 'comic':
        case 'magazine_novel':
          return true;
        default:
          return false;
      }
    };

    return {
      isNovel,
      gotoContentHome
    };
  }
});
