





















import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import Store from '@/store/index';
import ThumbnailBlinder from '@/components/atoms/templates/ThumbnailBlinder.vue';
import IconThumbnail from '@/components/atoms/templates/IconThumbnail.vue';
import ComicoOriginal from '@/components/atoms/templates/ComicoOriginal.vue';
import { ICON_WAITFREE } from '@/commons/constants/icon-types';
import { ACCEPT_MATURE } from '@/commons/constants/auth-types';
import { ContentInfo, Mature } from '@/models';
import { getCookie } from '@/helpers/cookies';

export default defineComponent({
  name: 'CardThumbnail',
  components: {
    ThumbnailBlinder,
    IconThumbnail,
    ComicoOriginal
  },
  props: {
    content: ContentInfo
  },
  setup(props, { root }) {
    const { getters } = Store;
    const mature: ComputedRef<Mature> = computed(
      () => getters['MyPageStore/mature']
    );
    const isAdultVerified: ComputedRef<boolean> = computed(() =>
      root['$isKo']
        ? mature.value.adultVerification?.status === 'verified'
        : getCookie(ACCEPT_MATURE) === 'Y'
    );
    const contentInfo: ComputedRef<ContentInfo> = computed(
      () => props.content as ContentInfo
    );
    const gaugeUsable: ComputedRef<boolean | undefined> = computed(
      () => contentInfo.value?.rentalConfig.gaugeUsable
    );
    const isBlinder: ComputedRef<boolean> = computed(() =>
      contentInfo.value.mature ? !isAdultVerified.value : false
    );

    const imagePathCheck = (imgPath?: string) => {
      if (imgPath) return imgPath;
      const tmpImgPath =
        root['$isKo'] || root['$isJa'] ? 'img_thumb' : 'img_thumb_pocket';
      return require('@/assets/img/' + tmpImgPath + '.png');
    };

    const imageLoadErrorCheck = (evt) => {
      evt.target.src = imagePathCheck();
    };

    return {
      isBlinder,
      ICON_WAITFREE,
      contentInfo,
      gaugeUsable,
      isAdultVerified,
      imagePathCheck,
      imageLoadErrorCheck
    };
  }
});
