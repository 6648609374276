import { SALES_CONFIG_EVENT, SALES_CONFIG_PURCHASEALLOWED, SALES_CONFIG_RENTALLOWED, TYPE_SALES } from '@/commons/constants/sales-types';
import { timeValue } from '@/services';
import {
  numToShortForm,
  arrToCommaStr,
  dateConvert
} from '@/services/common/utils';

export interface Author {
  avatarImageUrl: string;
  id: number;
  name: string;
  role: string;
  primary?: boolean;
  sort?: number;
}

export interface Badge {
  type: string;
  label: string;
}
export interface Genre {
  code: string;
  id: number;
  mature: boolean;
  name: string;
  primary: boolean;
}
export interface Thumbnail {
  type?: string;
  url: string;
}

export class SalesConfig {

  rentableRemained?: number;
  free: boolean;
  rentAllowedWith?: string[];
  purchaseAllowedWith?: string[];
  event?: string[];
  originalPrice?: number;
  price?: number;
  coinBackPrice: number;
  originalRentalPrice: number;
  rentalPrice: number;

  constructor(props: SalesConfig) {
    this.rentableRemained = props.rentableRemained;
    this.free = props.free;
    this.rentAllowedWith = props.rentAllowedWith;
    this.purchaseAllowedWith = props.purchaseAllowedWith;
    this.event = props.event;
    this.originalPrice = props.originalPrice;
    this.price = props.price;
    this.coinBackPrice = props.coinBackPrice;
    this.originalRentalPrice = props.originalRentalPrice;
    this.rentalPrice = props.rentalPrice;
  }
  /**
   * 게이지 대여 가능 여부
   */
  isGaugeRent() {
    return this.checkRentType(SALES_CONFIG_RENTALLOWED.GAUGE);
  }
  /**
   * 티켓 대여 가능 여부
   */
  isTicketRent() {
    return this.checkRentType(SALES_CONFIG_RENTALLOWED.TICKET);
  }
  /**
   * 코인 대여 가능 여부
   */
  isCoinRent() {
    return this.checkRentType(SALES_CONFIG_RENTALLOWED.COIN);
  }
  /**
   * 코인 구입 가능 여부
   */
  isCoinPurchase() {
    return this.checkPurchaseType(SALES_CONFIG_PURCHASEALLOWED.COIN);
  }
  /**
   * 무료 확인
   */
  isFree(): boolean {
    return this.free;
  }
  /**
   * 기다리면무료 사용 가능 여부
   * @param {Inventory} inventory 
   */
  isWaitForFree(inventory: Inventory): boolean {
    return (
      this.checkRentType(SALES_CONFIG_RENTALLOWED.GAUGE) &&
      inventory.getReadForFreeAmount() === 0 &&
      inventory.getTicketAmount() === 0
    );
  }
  /**
   * 티켓 사용 가능 여부
   * @param {Inventory} inventory 
   */
  isReadableTicket(inventory: Inventory) {
    const isReadableChapter = this.checkRentType(SALES_CONFIG_RENTALLOWED.TICKET);
    const isReadableInventory = inventory.getTicketAmount() > 0;
    return isReadableChapter && isReadableInventory;
  }
  /**
   * 게이지 사용 가능 여부
   * @param {Inventory} inventory 
   */
  isReadableGauge(inventory: Inventory) {
    const isReadableChapter = this.checkRentType(SALES_CONFIG_RENTALLOWED.GAUGE);
    const isReadableInventory = inventory.getReadForFreeAmount() > 0;
    return isReadableChapter && isReadableInventory;
  }
  /**
   * 챕터 대여 가능 여부
   */
  isRentChapter() {
    return this.rentAllowedWith && this.rentAllowedWith.length > 0;
  }
  /**
   * 챕터 구매 가능 여부
   */
  isPurchaseChapter() {
    return this.purchaseAllowedWith && this.purchaseAllowedWith.length > 0;
  }
  /**
   * 일치하는 이벤트가 있는지 확인
   * @param {string} type
   * @returns {boolean}
   */
  checkEventType(type: string) {
    if (this.event) {
      return this.event.filter(node => node === type).length > 0;
    } else {
      return false;
    }
  }
  /**
   * 일치하는 대여상태가 있는지 확인
   * @param {string} type
   * @returns {boolean}
   */
  checkRentType(type: string) {
    if (this.rentAllowedWith) {
      return this.rentAllowedWith.filter(node => node === type).length > 0;
    } else {
      return false;
    }
  }
  /**
   * 일치하는 구매상태가 있는지 확인
   * @param {string} type
   * @returns {boolean}
   */
  checkPurchaseType(type: string) {
    if (this.purchaseAllowedWith) {
      return this.purchaseAllowedWith.filter(node => node === type).length > 0;
    } else {
      return false;
    }
  }
  /**
   * 가격 반환
   * @returns {number} 
   */
  getPrice() {
    return this.price ? this.price : 0;
  }
}

export class RentalConfig {
  gaugeUsable?: boolean;
  recoverInterval?: number;
  rentalPeriod?: number;

  constructor(props?: RentalConfig) {
    this.gaugeUsable = props?.gaugeUsable;
    this.recoverInterval = props?.recoverInterval;
    this.rentalPeriod = props?.rentalPeriod;
  }
}

export class ChapterImage {
  width: number;
  height: number;
  parameter: string;
  sort: number;
  url: string;

  constructor(props: ChapterImage) {
    this.width = props.width;
    this.height = props.height;
    this.parameter = props.parameter;
    this.sort = props.sort;
    this.url = props.url;
  }
}

export interface NextPrevChapter {
  id: number;
  name: string;
  thumbnail: Thumbnail;
  hasTrial: boolean;
}

export interface AuthorComment {
  author: Author;
  comment: string;
}

export interface Epub {
  url: string;
  decryptKey?: string;
  fileSize: number;
  isTrial: boolean;
  parameter: string;
}

export class Chapter {
  activity?: Activity;
  id?: number;
  volumeId: number;
  name?: string;
  publishedAt?: string;
  salesConfig: SalesConfig;
  sort: number;
  thumbnail: Thumbnail;
  images?: ChapterImage[];
  hasTrial?: boolean;
  nextChapter?: NextPrevChapter;
  previousChapter?: NextPrevChapter;
  authorComment?: AuthorComment;
  epub?: Epub;

  constructor(props: Chapter) {
    this.volumeId = props.volumeId;
    this.activity = props.activity || undefined;
    this.id = props.id;
    this.name = props.name;
    this.publishedAt = props.publishedAt || undefined;
    this.salesConfig = new SalesConfig(props.salesConfig);
    this.sort = props.sort;
    this.thumbnail = props.thumbnail || undefined;
    this.hasTrial = props.hasTrial;
    this.nextChapter = props.nextChapter;
    this.previousChapter = props.previousChapter;
    this.authorComment = props.authorComment;
    this.epub = props.epub;
    if (props.images) {
      this.images = props.images.map((node) => new ChapterImage(node));
    }
  }
  publishedAtToStr(locale: string) {
    if (!this.publishedAt) return;
    const current = new Date(this.publishedAt);
    if (locale === 'ko') {
      return `${current.getFullYear()}${timeValue[4]} ${current.getMonth() + 1
        }${timeValue[3]} ${current.getDate()}${timeValue[2]}`;
    } else {
      return current.toLocaleString(locale, {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    }
  };

  isUnlock(): boolean | undefined {
    return this.activity?.unlocked;
  }
  isRented(): boolean | undefined {
    return this.activity?.rented;
  }
  getRentRemined() {
    return this.activity && this.activity.rentRemained ? this.activity.rentRemained : 0;
  }
  getThumbnail(): string {
    return this.thumbnail.url;
  }

  getSalesState(inventory: Inventory | undefined, adsEnabled: boolean) {
    if (this.activity?.unlocked) return TYPE_SALES.PURCHASED;        // 구입완료
    if (this.salesConfig.free) {
      if (this.salesConfig.checkEventType(SALES_CONFIG_EVENT.FREE_INCREASED)) {
        return TYPE_SALES.TARGET_FREE;  // 당신에게만 무료
      } else {
        return TYPE_SALES.FREE;         // 무료
      }
    } else {
      if (this.salesConfig.rentAllowedWith && this.salesConfig.rentAllowedWith.length > 0) {
        if (adsEnabled) return TYPE_SALES.PRICE;      // 광고시 코인으로 구입
        if (inventory) {
          if (this.getRentRemined()) return TYPE_SALES.REMAINED;   // 대여기간 n남음
          if (this.salesConfig.isGaugeRent() && this.salesConfig.isTicketRent()) {
            // 티켓 & 게이지 둘다 볼수 있는 작품인 경우
            if (this.salesConfig.isReadableGauge(inventory) || this.salesConfig.isReadableTicket(inventory)) {
              return TYPE_SALES.READ_NOW; // 지금 읽기
            } else if (this.salesConfig.isWaitForFree(inventory)) {
              return TYPE_SALES.WAITFREE; // 기다리면 무료
            } else {
              return TYPE_SALES.PRICE;
            }
          } else if (this.salesConfig.isGaugeRent() && !this.salesConfig.isTicketRent()) {
            // 게이지로만 볼수 있는 작품
            if (this.salesConfig.isReadableGauge(inventory)) {
              return TYPE_SALES.READ_NOW; // 지금 읽기
            } else if (this.salesConfig.isWaitForFree(inventory)) {
              return TYPE_SALES.WAITFREE; // 기다리면 무료
            } else {
              return TYPE_SALES.PRICE;
            }
          } else if (this.salesConfig.isTicketRent() && !this.salesConfig.isGaugeRent()) {
            // 티켓으로만 볼수 있는 작품
            if (this.salesConfig.isReadableTicket(inventory)) {
              return TYPE_SALES.READ_NOW; // 지금 읽기
            } else {
              return TYPE_SALES.PRICE;
            }
          } else {
            return TYPE_SALES.PRICE;
          }
        } else {
          // 비광고 작품 > 비로그인상태(inventory x)는 가입유도를 위해 read now 표기
          return TYPE_SALES.READ_NOW;
        }
      } else {
        return TYPE_SALES.PRICE;      // 코인으로 구입
      }
    }
  }

  isPrev(): boolean {
    return this.previousChapter ? true : false;
  }
  isNext(): boolean {
    return this.nextChapter ? true : false;
  }

  isOnlyRental(): boolean {
    if (this.salesConfig.purchaseAllowedWith) {
      return false;
    } else {
      return this.salesConfig.rentAllowedWith && this.salesConfig.rentAllowedWith.length > 0 ? true : false;
    }
  }
}

export class Inventory {
  coin?: {
    freeAmount: number;
    purchasedAmount: number;
    closestExpireAmount?: number;
    closestExpireAt?: string;
  };
  readForFree?: {
    amount?: number;
    recoverRemained?: number;
  };
  ticket?: {
    amount?: number;
  };
  constructor(props: Inventory) {
    this.coin = props.coin;
    this.readForFree = props.readForFree;
    this.ticket = props.ticket;
  }
  getTicketAmount(): number {
    return this.ticket && this.ticket.amount && this.ticket.amount > 0
      ? this.ticket.amount
      : 0;
  }
  getReadForFreeAmount(): number {
    return this.readForFree &&
      this.readForFree.amount &&
      this.readForFree.amount > 0
      ? this.readForFree.amount
      : 0;
  }
  getCoinTotalAmount(): number {
    return (
      (this.coin?.freeAmount ? this.coin?.freeAmount : 0) +
      (this.coin?.purchasedAmount ? this.coin?.purchasedAmount : 0)
    );
  }
}

export interface LastChapterLocator {
  href: string;
  locations: {
    progression: number;
  };
  type: string;
}

export interface Activity {
  subscribed?: boolean;
  lastReadChapterLocator: LastChapterLocator;
  lastReadChapterId: number;
  lastReadAt?: string;
  unlocked?: boolean;
  rented?: boolean;
  read?: boolean;
  rentRemained?: number;
}

export interface SNS {
  link?: string;
  tags?: string[];
  text?: string;
}

export class ContentInfo {
  type: string;
  id: number;
  name: string;
  chapterUnit: string;
  activity: Activity;
  adsEnabled: boolean;
  authors: Author[];
  chapterFileFormat: string;
  chapters: Chapter[];
  commentEnabled?: boolean;
  badge?: Badge;
  description: string;
  genres: Genre[];
  icon: string;
  inventory?: Inventory;
  keyVisual?: { url: string };
  lastChapterPublishedAt: string;
  mature: boolean;
  original: boolean;
  rentExpireAt?: string;
  rentalConfig: RentalConfig;
  sns?: SNS;
  status?: string;
  themeColor?: string;
  thumbnails: Thumbnail[];
  totalViews: number;
  restrictedAge?: number;
  publishDay: string;
  publisherName?: string;
  exclusive?: boolean;
  orientation?: string;

  constructor(prop: ContentInfo) {
    this.activity = prop.activity || undefined;
    this.adsEnabled = false;
    this.authors = prop.authors || [];
    this.badge = prop.badge || undefined;
    this.chapterFileFormat = prop.chapterFileFormat || '';
    this.chapterUnit = prop.chapterUnit || '';
    this.chapters = prop.chapters
      ? prop.chapters.map((node) => new Chapter(node))
      : ([] as Chapter[]);
    this.commentEnabled = prop.commentEnabled || false;
    this.description = prop.description || '';
    this.genres = prop.genres || [];
    this.icon = prop.icon || '';
    this.id = prop.id || 0;
    this.inventory = prop.inventory ? new Inventory(prop.inventory) : undefined;
    this.keyVisual = prop.keyVisual;
    this.lastChapterPublishedAt = prop.lastChapterPublishedAt || '';
    this.mature = prop.mature || false;
    this.name = prop.name || '';
    this.original = prop.original || false;
    this.exclusive = prop.exclusive || false;
    this.themeColor = prop.themeColor || '';
    this.thumbnails = prop.thumbnails || undefined;
    this.totalViews = prop.totalViews || 0;
    this.type = prop.type || '';
    this.restrictedAge = prop.restrictedAge;
    this.publishDay = prop.publishDay;
    this.publisherName = prop.publisherName;
    this.rentExpireAt = prop.rentExpireAt;
    this.rentalConfig = new RentalConfig(prop.rentalConfig);
    this.sns = prop.sns;
    this.status = prop.status || '';
    this.orientation = prop.orientation;
  }

  getThumbnail(type: 'cover' | 'key_visual' | string): string {
    const result = this.thumbnails?.filter((node) => {
      return node.type === type;
    });
    return result && result.length > 0 ? result[0].url : '';
  }

  getKeyVisual(): string | undefined {
    return this.keyVisual && this.keyVisual.url
      ? this.keyVisual.url
      : undefined;
  }

  getGenreToStr(len?: number): string {
    const result = this.genres.map((node) => node.name);
    return this.genres ? arrToCommaStr(result, len) : '';
  }

  /**
   * 특정 인덱스와 일치하는 작가 리턴
   * @param index
   * @returns
   */
  getAuthorIndex(index: number) {
    return this.authors
      .filter((node, idx) => idx === index)
      .map((node) => node.name);
  }

  /**
   * role 과 일치하는 작가 리턴
   * @param role
   * @param maxLen 
   * @returns 
   */
  getAuthorRole(role: string, maxLen?: number): string {
    const result = this.authors.filter((node) => node.role === role).map(node => node.name);
    return maxLen ? result.slice(0, maxLen).join(', ') : result.join(', ');
  }

  /**
   * maxLen 에 맞는 작가 리턴
   * @param maxLen 
   * @returns 
   */
  getAuthor(maxLen?: number): string {
    const result = this.authors.map(node => node.name);
    return maxLen ? result.slice(0, maxLen).join(', ') : result.join(', ');
  }

  getTotalView(locale: string): string {
    return this.totalViews ? numToShortForm(this.totalViews, locale) : '0';
  }

  getLastPublished(): { time: number | string; type: string } {
    return dateConvert(this.lastChapterPublishedAt);
  }

  getlastReadAt(views: string): { time: number | string; type: string } {
    if (!this.activity) {
      return { time: 0, type: 'sec' };
    }
    return dateConvert(this.activity.lastReadAt, views);
  }

  getRentExpireAt(views: string): { time: number | string; type: string } {
    if (!this.rentExpireAt) {
      return { time: 0, type: 'sec' };
    }
    return dateConvert(this.rentExpireAt, views);
  }

  getNovelType(): boolean {
    switch (this.type) {
      case 'novel':
      case 'magazine_novel':
        return true;
      default:
        return false;
    }
  }

  getLastReadChapterId(): number | undefined {
    return this.activity.lastReadChapterId;
  }

  getAuthorRoleSearch(role: string) {
    const result = this.authors.filter((node) => node.role === role).map(node => node.name)
    return result.length > 0 ? result : false
  }

  isIncludePurchaseChapter() {
    const result = this.chapters.filter(node => {
      const { salesConfig } = node;
      return salesConfig.isCoinPurchase();
    });
    return result.length > 0;
  }

  isIncludeRentableChapter() {
    const result = this.chapters.filter(node => {
      const { salesConfig } = node;
      return salesConfig.isGaugeRent() || salesConfig.isTicketRent() || salesConfig.isCoinRent();
    });
    return result.length > 0;
  }
}
