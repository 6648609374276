import { Page } from '@/models';
import { Comment } from '@/models/comment-info';
import { commentApi } from '@/services/comment/comment.api';
import { Module } from 'vuex';
import { RootState } from '..';

export interface ChapterCommentTypes {
	comments: Comment[];
	contentType: string | null;
	contentId: number | null;
	chapterId: number | null;
	orderType: 'likes' | 'recent',
	page: Page;
	firstTotalElements: number;
};


export const ChapterCommentStore: Module<ChapterCommentTypes, RootState> = {
	namespaced: true,
	state: {
		comments: [],
		contentType: null,
		contentId: null,
		chapterId: null,
		orderType: 'likes',
		page: {} as Page,
		firstTotalElements: 0
	},
	mutations: {
		comments(state: ChapterCommentTypes, value: Comment[]) {
			state.comments = value;
		},
		contentType(state: ChapterCommentTypes, value: string) {
			state.contentType = value;
		},
		contentId(state: ChapterCommentTypes, value: number) {
			state.contentId = value;
		},
		chapterId(state: ChapterCommentTypes, value: number) {
			state.chapterId = value;
		},
		orderType(state: ChapterCommentTypes, value: 'likes' | 'recent') {
			state.orderType = value;
		},
		page(state, value: Page) {
			state.page = value;
		},
		firstTotalElements(state, value: number) {
			state.firstTotalElements = value;
		}
	},
	getters: {
		comments: state => state.comments,
		contentType: state => state.contentType,
		contentId: state => state.contentId,
		chapterId: state => state.chapterId,
		orderType: state => state.orderType,
		page: state => state.page,
		firstTotalElements: state => state.firstTotalElements
	},
	actions: {
		async initComments({ state, commit }, options: {
			contentType: string,
			contentId: number,
			chapterId: number,
			lastRespondedSeqNo?
		}) {
			const result = await commentApi.getChapterComment({
				contentType: options.contentType,
				contentId: options.contentId,
				chapterId: options.chapterId,
				orderType: state.orderType
			}, options.lastRespondedSeqNo);
			const comments = result.comments.map(node => new Comment(node));
			// const removeReported = CommentHelperService.removeReportedComment(comments);
			commit('contentType', options.contentType);
			commit('contentId', options.contentId);
			commit('chapterId', options.chapterId);
			commit('orderType', state.orderType);
			commit('page', result.page);
			commit('comments', comments);
			commit('firstTotalElements', result.page?.totalElements);
		},
		async appendComments({ state, commit }, lastRespondedSeqNo) {
			const { contentType, contentId, chapterId } = state;
			if(contentType && contentId && chapterId) {
				const result = await commentApi.getChapterComment(
					{
						contentType,
						contentId,
						chapterId,
						orderType: state.orderType
					},
					lastRespondedSeqNo
				);
				const comments = result.comments.map(node => new Comment(node));
				commit('comments', [
					...state.comments,
					...comments
				]);
				commit('page', result.page);
			}
		},
		async saveComment({ state, dispatch }, comment) {
			const { contentType, contentId, chapterId } = state;
			if(contentType && contentId && chapterId) {
				await commentApi.saveComment({
					contentType,
					contentId,
					chapterId
				}, comment);
				dispatch('initComments', {
					contentType,
					contentId,
					chapterId
				});
				return true;
			} else {
				return false;
			}
		},
		async deleteComment({ state, dispatch }, { commentId }) {
			const { contentType, contentId, chapterId } = state;
			if(!contentType) return;
			await commentApi.deleteComment({
				contentType,
				commentId
			});
			dispatch('initComments', {
				contentType,
				contentId,
				chapterId
			});
		},
		async likeComment({ state, dispatch }, { commentId }) {
			const { contentType, contentId, chapterId } = state;
			if(!contentType) return;
			await commentApi.likeComment({
				contentType,
				commentId
			});
			dispatch('initComments', {
				contentType,
				contentId,
				chapterId
			});
		}
	},
};
