import { DialogStoreOption } from '@/store/dialog';
import { Commit } from 'vuex';
import router from '@/router';
import { ERROR_CODES } from '@/commons/constants/error-codes';
import AlertDialog from '@/dialogs/AlertDialog.vue';
import { i18nTxt } from '@/commons/i18n';

export interface SavePopup {
  isSkip: boolean;
  id: number | string;
  repeatable: boolean;
  repeatInterval?: number;
}

const getPopupIndex = (popupMaxIndex, key) => {
  const index = localStorage.getItem(key);
  if (index) {
    if (popupMaxIndex - 1 >= +index + 1) {
      return +index + 1;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

const checkLatestPopupTime = (key) => {
  const latest = localStorage.getItem(key);
  const current = new Date().getTime();
  if (latest) {
    const gap = (current - +latest) / 1000;
    return gap > 60 * 10;
    // return gap > 5;
  } else {
    return true;
  }
};

const checkSkipPopup = (id: string) => {
  const isSkip = localStorage.getItem('isSkip');
  if (isSkip) {
    const arr = JSON.parse(isSkip);
    return arr.filter((node) => node == id).length > 0 ? false : true;
  } else {
    return true;
  }
};

const savePopupInfo = (index, closePopupInfo, popup, latestTimeKey, latestIndexKey) => {
  localStorage.setItem(latestTimeKey, new Date().getTime() + '');
  localStorage.setItem(latestIndexKey, index + '');
  if (closePopupInfo && closePopupInfo.isSkip) {
    const isSkip = localStorage.getItem('isSkip');
    if (isSkip) {
      const skipArr = JSON.parse(isSkip);
      skipArr.push(popup.id);
      localStorage.setItem('isSkip', JSON.stringify(skipArr));
    } else {
      localStorage.setItem('isSkip', JSON.stringify([popup.id]));
    }
  }
};

const headerFixed = (commit: Commit, state: boolean | null): void => {
  commit('DialogStore/currentDialog', state);
};

export const getErrorDialogOptions = ({ message }: { message: string }) => {
  const options: DialogStoreOption = {
    component: () => import('@/dialogs/ErrorDialogWithCallback.vue'),
    param: {
      title: '',
      message
    }
  };
  return options;
};

export const getErrorDialogCallback = ({ code }) => {
  switch (code) {
    case ERROR_CODES.CODE_303:
      break; // 기존 화면으로(동작 x)
    case ERROR_CODES.CODE_404:
      router.push('/');
      break;
    case ERROR_CODES.CODE_304:
    default:
      location.reload();
      break;
  }
};

export const duplicatedReport = (root, message: string) => {
  root.$q.dialog({
    component: AlertDialog,
    parent: root,
    componentProps: {
      title: '',
      message,
      ok: i18nTxt('ok'),
      html: true
    }
  });
};

export const forbiddenReport = (root, message: string) => {
  root.$q.dialog({
    component: AlertDialog,
    parent: root,
    componentProps: {
      title: '',
      message,
      ok: i18nTxt('ok'),
      html: true
    }
  });
};

export const DialogHelperServicepup = {
  headerFixed,
  checkSkipPopup,
  checkLatestPopupTime,
  getPopupIndex,
  savePopupInfo,
  duplicatedReport,
  forbiddenReport
};
