import { i18nFormatting, i18nTxt, i18nWrapRuby, t } from "@/commons/i18n";
import { CoinTxtInfo, ContentInfo } from "@/models";
import { Badge } from "@/models/content-info";
import { ChapterHelperService, gotoLinkPage, toSingular } from "..";
import { ContentsApiService } from "./contents.api";
import router from '@/router';
import { READING_GUIDE_DIRECTION_LTR, READING_GUIDE_DIRECTION_RTL, READING_GUIDE_DIRECTION_TTB } from "@/commons/constants/viewer-types";

export const getBadgeStatus = (badgeInfo: Badge, statusInfo: string): { type: string | undefined; label?: string } => {
	if(badgeInfo) {
		if(badgeInfo.type && badgeInfo.type === 'event') {
			return {
				type: badgeInfo.type,
				label: badgeInfo.label
			};
		} else {
			return {
				type: statusInfo
			};
		}
	} else {
		return {
			type: statusInfo
		};
	}
};

/**
 * urlScheme spliter
 * @param urlScheme 
 * @returns 
 */
export const separateUrl = (urlScheme: string) => {
	const str = urlScheme.split('//')[1].split('/');
	str.shift();
	return str;
}

export const getForwardPath = (urlScheme: string) => {
	const first = urlScheme.split('//');
	if(first.length > 1) {
		const second = first[1].split('/');
		second[0] = `${first[0]}//${second[0]}`;
		if(second[0] === process.env.VUE_APP_DOMAIN) {
			second.shift();
			return {
				internal: true,
				path: `/${second.join('/')}`
			};
		} else {
			return {
				internal: false,
				path: second.join('/')
			}
		}
	} else {
		return {
			internal: true,
			path: urlScheme
		}
	}
}

export const isContentPath = (str: string[]) => {
	switch(str[0]) {
		case 'comic':
		case 'magazine_comic':
		case 'novel':
		case 'magazine_novel': {
			return true;
		}
		default: return false;
	}
}
export const isChapterPath = (str: string[]) => str[2] === 'chapter';

export const getContentChapterPath = (str: string[]) => {
	switch(str[0]) {
		case 'comic':
		case 'magazine_comic':
		case 'novel':
		case 'magazine_novel': {
			const contentType = str[0];
			const contentId = str[1];
			const chaterId = str[3];
			const chapterFileSalesType: string = str[4];
			if(str.length === 2) {
				return {
					contentType,
					contentId: +contentId
				}
			} else {
				return {
					contentType,
					contentId: +contentId,
					chapterId: +chaterId,
					chapterFileSalesType
				}
			}
		}
	}
};


const createRentBuyCoinTxt = (
	type: string,
	{
		price,
		originalPrice,
		coinBackPrice,
		rentalPeriod
	}: CoinTxtInfo,
	lang: string
) => {
	let text = '';
	let str = '';
	if(price !== undefined) {
		let value = '';
		if(originalPrice && originalPrice !== price) {
			value = `<span class="txt_cancle">${originalPrice}</span> ${price}`;
		} else {
			value = price + '';
		}
		if(rentalPeriod){
			str = i18nFormatting(type, {
        price: value,
        rentalPeriod: (rentalPeriod / 60 / 60) < 24 ?  (rentalPeriod / 60 / 60) + i18nTxt('hour') : (rentalPeriod / 60 / 60 / 24) + i18nTxt('day')
      });
		} else {
		  str = i18nFormatting(type, {
			  price: value
		  });
		}
		text = toSingular(price, str, lang);
	}
	if(coinBackPrice) {
		text += i18nWrapRuby(t('coinback'), coinBackPrice + '');
	}
	return text;
};

export const isVerticalThumbnail = (contentType: string) => {
	switch(contentType) {
		case 'magazine_comic':
		case 'magazine_novel':
		case 'novel':
			return true;
		default:
			return false;
	}
};
export const isMagazine = (contentType: string) => {
	switch(contentType) {
		case 'magazine_comic':
		case 'magazine_novel':
			return true;
		default:
			return false;
	}
}

export const isNovel = (contentType: string) => {
	switch(contentType) {
		case 'novel':
		case 'magazine_novel':
			return true;
		default:
			return false;
	}
}

export const getAgeGroup = (age: number | undefined) => {
	if(!age) return null;
	switch(age) {
		case 0:
			return '0';
		case 12:
			return '12';
		case 13:
		case 14:
		case 15:
			return '15';
		case 16:
		case 17:
		case 18:
		case 19:
			return '19';
	}
	// switch(age) {
	// 	case 0:
	// 	case 12:
	// 	case 13:
	// 	case 14:
	// 	case 15:
	// 	case 16:
	// 	case 17:
	// 	case 18:
	// 	case 19:
	// 		return i18nTxt(`restricted_age_${age}`);
	// 	default:
	// 		return '';
	// }
};

const imageStyleAdd = (id: string, backgroundOption: string) => {
	const css = `.${id} { ${backgroundOption} }`;
	const target = document.querySelector(`.${id}`);
	const style = document.createElement('style');

	if(target) {
		target.parentElement?.appendChild(style);
		style.type = 'text/css';
		style.appendChild(document.createTextNode(css));
	}
};

export const getPageMode = (orientation: string) => {
	switch(orientation) {
		case READING_GUIDE_DIRECTION_TTB:
			return null;
		case READING_GUIDE_DIRECTION_RTL:
		case READING_GUIDE_DIRECTION_LTR:
			return 'horizontal';
	}
}

export const ContentsHelperService = {
	getBadgeStatus,
	separateUrl,
	createRentBuyCoinTxt,
	isVerticalThumbnail,
	isMagazine,
	isContentPath,
	isChapterPath,
	getContentChapterPath,
	getAgeGroup,
	imageStyleAdd,
	isNovel,
	getPageMode,
	getForwardPath
}