





















import {
  computed,
  ComputedRef,
  defineComponent,
  onUnmounted,
  watch
} from '@vue/composition-api';
import store from '@/store';
import ChapterCard from '@/components/molecules/chapter-card/ChapterCard.vue';
import { DialogStoreOption } from '@/store/dialog';
import { Chapter, ContentInfo, Inventory } from '@/models';
import { LCS_TYPES } from '@/commons/constants/lcs-types';
import { isVerticalThumbnail, isMagazine } from '@/services';

export default defineComponent({
  name: 'ChapterListModule',
  components: {
    ChapterCard
  },
  props: {
    content: ContentInfo,
    chapters: Array,
    contentType: String,
    contentId: Number,
    tabType: String
  },
  setup(props, { emit, root }) {
    const { dispatch, getters } = store;
    const contentType: ComputedRef<string | undefined> = computed(
      () => props.contentType
    );
    const tabType: ComputedRef<string> = computed(
      () => props.tabType as string
    );
    const contentInfo: ComputedRef<ContentInfo | undefined> = computed(
      () => props.content
    );
    const inventory: ComputedRef<Inventory | undefined> = computed(
      () => contentInfo.value?.inventory
    );
    const list: ComputedRef<Chapter[] | undefined> = computed(
      () => props.chapters as Chapter[]
    );
    let volumeStore: { string: number[] } = {} as { string: number[] };

    const isSkipThumbnail = (chapter: Chapter) => {
      if (
        (contentType.value === 'magazine_comic' ||
          contentType.value === 'magazine_novel') &&
        tabType.value === 'EPISODE'
      ) {
        if (volumeStore[chapter.volumeId]) {
          volumeStore[chapter.volumeId].push(chapter.id);
          return true;
        } else {
          volumeStore[chapter.volumeId] = [chapter.id];
          return false;
        }
      }
    };

    const clearVoumnIdStore = () => {
      volumeStore = {} as { string: number[] };
    };

    const sendPurchase = ({ content, chapter }) => {
      const options: DialogStoreOption = {
        component: () => import('@/dialogs/PurchaseDialog.vue'),
        param: {
          prevPage: LCS_TYPES.CONTENTS_HOME,
          parent: root,
          content,
          chapter
        }
      };
      dispatch('DialogStore/dialog', options).then((res) => {
        if (res) {
          // sendPurchaseDialog(res);
          emit('emitPurchaseDialog', res);
        }
      });
    };

    watch(list, () => {
      clearVoumnIdStore();
    });

    onUnmounted(() => {
      clearVoumnIdStore();
    });

    return {
      list,
      inventory,
      contentInfo,
      isSkipThumbnail,
      sendPurchase,
      isVerticalThumbnail,
      isMagazine
    };
  }
});
