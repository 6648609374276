


























import { AuthorComment } from '@/models';
import { lineBreak } from '@/services';
import { computed, ComputedRef, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AuthorCommentCard',
  props: {
    authorComment: Object
  },
  setup(props) {
    const author: ComputedRef<AuthorComment> = computed(
      () => props.authorComment?.author
    );
    const description = computed(() => {
      try {
        const origin: string = props.authorComment?.comment;
        return lineBreak(origin);
      } catch (e) {
        return props.authorComment?.comment || '';
      }
    });
    return {
      author,
      description
    };
  }
});
