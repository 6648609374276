import { i18nTxt, i18nWrapRuby, t } from '@/commons/i18n';
import {
  DAYS_LATER,
  HOUR_LATER,
  MIN_LATER,
  READ_NOW,
  RemainedTypes
} from '../contents/chapter.helper';
import { toSingular } from '@/services/common/context.helper';
import { Language } from '@/commons/constants/enum';
import { getLocale } from '@/commons/i18n';

export const timeValue: string[] = i18nTxt('time_type').toString().split('_');

export const setTimeToken = (key: string, str: string): string =>
  i18nWrapRuby(t(key), str);

export const getRemainedStr = (
  remained: RemainedTypes,
  lang: Language
): string => {
  switch (remained.type) {
    case DAYS_LATER: // n일 남음
      return `${setTimeToken('in', remained.time)} ${toSingular(
        +remained.time,
        timeValue[2],
        lang
      )}`;
    case HOUR_LATER: // n시간 남음
      return `${setTimeToken('in', remained.time)} ${toSingular(
        +remained.time,
        timeValue[1],
        lang
      )}`;
    case MIN_LATER: // n분 남음
      const num = remained.time === '0' ? '1' : remained.time;
      return `${setTimeToken('in', num)} ${toSingular(
        +remained.time,
        timeValue[0],
        lang
      )}`;
    case READ_NOW: // 지금읽기
      return i18nTxt('gauge_read_now').toString();
  }
};

export const getRentalRemainedStr = (
  remained: RemainedTypes,
  lang: Language
): string => {
  if (lang !== 'ja-JP') {
    switch (remained.type) {
      case DAYS_LATER: {
        // 대여시간 n일 남음
        const label = `${remained.time}${toSingular(
          +remained.time,
          timeValue[2],
          lang
        )}`;
        return `${setTimeToken('available_for', label)}`;
      }
      case HOUR_LATER: {
        // 대여시간 n시간 남음
        const label = `${remained.time}${toSingular(
          +remained.time,
          timeValue[1],
          lang
        )}`;
        return `${setTimeToken('available_for', label)} `;
      }
      case MIN_LATER: {
        // 대여시간 n분 남음
        const num = remained.time === '0' ? '1' : remained.time;
        const label = `${num}${toSingular(+remained.time, timeValue[0], lang)}`;
        return `${setTimeToken('available_for', label)} `;
      }
      default:
        return '';
    }
  } else {
    switch (remained.type) {
      case DAYS_LATER: // 대여시간 n일 남음
        return `${setTimeToken(
          'available_for',
          remained.time + toSingular(+remained.time, timeValue[2], lang)
        )}`;
      case HOUR_LATER: // 대여시간 n시간 남음
        return `${setTimeToken(
          'available_for',
          remained.time + toSingular(+remained.time, timeValue[1], lang)
        )}`;
      case MIN_LATER: // 대여시간 n분 남음
        const num = remained.time === '0' ? '1' : remained.time;
        return `${setTimeToken(
          'available_for',
          num + toSingular(+remained.time, timeValue[0], lang)
        )}`;
      default:
        return '';
    }
  }
};

export const getRemainedWaitForFree = (remained: RemainedTypes): string => {
  switch (remained.type) {
    case DAYS_LATER: // n일 기다리면 무료
      return `${i18nWrapRuby(t('rentable_in'), remained.time + timeValue[2])}`;
    case HOUR_LATER: // n시간 기다리면 무료
      return `${i18nWrapRuby(t('rentable_in'), remained.time + timeValue[1])}`;
    case MIN_LATER: // n분 기다리면 무료
      return `${i18nWrapRuby(t('rentable_in'), remained.time + timeValue[0])}`;
    default:
      return '';
  }
};

export const leftDateStr = (value: string, timeStr: string): string => {
  const lang = getLocale() || process.env.VUE_APP_LANGUAGE;
  const timeToken = `${value} ${toSingular(+value, timeStr, lang)}`;
  let leftDate = setTimeToken('left', timeToken);
  if (lang === 'fr-FR' && +value > 1) leftDate += 's'; // POEditor 프랑스어에만 복수 (s)붙어서 별도 예외 처리
  return leftDate;
};

export const toNoticeDateStr = (expireRemained: number) => {
  try {
    const hour = 60 * 60;
    const day = hour * 24;
    if (expireRemained > hour) {
      if (expireRemained > day) {
        const value = (expireRemained / 60 / 60 / 24).toFixed(0);
        // n일 남음
        return leftDateStr(value, timeValue[2]);
      } else {
        const value = (expireRemained / 60 / 60).toFixed(0);
        // n시간 남음
        return leftDateStr(value, timeValue[1]);
      }
    } else {
      const value = (expireRemained / 60).toFixed(0);
      // n분 남음
      return leftDateStr(value, timeValue[0]);
    }
  } catch (e) {
    return null;
  }
};

export const DateTokenHelperService = {
  timeValue,
  getRemainedStr,
  setTimeToken,
  getRentalRemainedStr,
  getRemainedWaitForFree,
  toNoticeDateStr
};
