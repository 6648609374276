import { WebPushService } from './web-push.api';

export const PUBLIC_KEY = "BG9k-0Jk-VgBD0_CyQPudJeYPpWZdnhvoRhD8anmfJYNxICY034sriv4msg3-7WDwlJ7TNYOuxP38kfSzn5zKMM";

export const checkNotification = (): void => {
  if (!isSupported()) return;
  Notification.requestPermission().then((status) => {
    if (status === "granted") {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.register('service-worker.js')
        .then((registration) => {
          registration.pushManager.getSubscription()
          .then((subscription) => {
            if (!(subscription === null)) {
              updateSubscription(subscription);
            } else {
              subscribe(registration);
            }
          });
        });
      }
    }
  });
};

export const isSupported = () =>
    'Notification' in window

export const updateSubscription = (subscription: PushSubscription): void => {
  WebPushService.updateSubscription(subscription);
};

export const subscribe = (registration: ServiceWorkerRegistration): void => {
  const subscribeOption = {
    userVisibleOnly: true,
    applicationServerKey: PUBLIC_KEY
  };
  registration.pushManager.subscribe(subscribeOption)
  .then((subscription) => {
    WebPushService.subscribe(subscription);
  });
};

export const unsubscribe = (): void => {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    navigator.serviceWorker.ready
    .then((registration) => {
      registration.pushManager.getSubscription()
      .then((subscription) => {
        if (subscription) {
          WebPushService.unsubscribe(subscription.endpoint);
        }
        return subscription;
      })
      .then((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    });
  }
};
