






















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'PocketComicsCopyright',
  setup(props, { emit }) {
    const onReportClick = () => emit('emitReport', {});
    return {
      onReportClick
    };
  }
});
