
































import {
  computed,
  ComputedRef,
  defineComponent,
  Ref,
  ref
} from '@vue/composition-api';
import ExpireRemained from '@/components/atoms/icons/ExpireRemained.vue';
import IconGotoLink from '@/components/atoms/icons/IconGotoLink.vue';
import {
  timeValue,
  setTimeToken,
  toNoticeDateStr
} from '@/services/common/date-token.helper';

export default defineComponent({
  name: 'NoticeLi',
  components: {
    ExpireRemained,
    IconGotoLink
  },
  props: {
    clsName: String,
    banner: Object
  },
  setup(props, { emit }) {
    const cls = computed(() => (props.clsName ? props.clsName : 'emph_new'));
    const isError: Ref<boolean> = ref(false);
    const label: ComputedRef<string> = computed(() => props.banner?.title);
    const expireRemained: ComputedRef<number> = computed(
      () => props.banner?.expireRemained
    );
    const category: ComputedRef<number> = computed(
      () => props.banner?.category
    );
    const webLinkUrl: ComputedRef<string> = computed(
      () => props.banner?.webLinkUrl
    );
    const toDateStr = (expireRemained: number) => {
      const str = toNoticeDateStr(expireRemained);
      if (str) {
        return str;
      } else {
        return '';
      }
    };
    const onLiClick = () => emit('emitNotice', props.banner);

    const sendNclick = () => emit('emitSendNclick', props.banner);

    return {
      cls,
      isError,
      label,
      expireRemained,
      category,
      webLinkUrl,
      onLiClick,
      sendNclick,
      toDateStr
    };
  }
});
