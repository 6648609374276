import { Module } from 'vuex';
import { RootState } from '@/store/index';
import {
  CoinHistoryInfo,
  CoinSalesConfig,
  CreditCardInfo
} from '@/models/coin-info';
import { CoinService } from '@/services/coin/coin.api';

export interface CoinHistoryInterface {
  coinHistoryInfo: CoinHistoryInfo;
  coinDistributionInfo: CoinHistoryInfo;
  coinSalesConfig: CoinSalesConfig;
  creditCardInfo: CreditCardInfo;
  paymentToken: string;
}

export const CoinStore: Module<CoinHistoryInterface, RootState> = {
  namespaced: true,
  state: {
    coinHistoryInfo: {} as CoinHistoryInfo,
    coinDistributionInfo: {} as CoinHistoryInfo,
    coinSalesConfig: {} as CoinSalesConfig,
    creditCardInfo: {} as CreditCardInfo,
    paymentToken: ''
  },
  getters: {
    history(state) {
      return state.coinHistoryInfo.history;
    },
    distribution(state) {
      return state.coinDistributionInfo.history;
    },
    salesConfig(state) {
      return state.coinSalesConfig.sales;
    },
    getCreditCardInfo(state) {
      return state.creditCardInfo;
    }
  },
  mutations: {
    coinHistoryInfo(state, value: CoinHistoryInfo) {
      state.coinHistoryInfo = value;
    },
    coinDistributionInfo(state, value: CoinHistoryInfo) {
      state.coinDistributionInfo = value;
    },
    coinSalesConfigInfo(state, value: CoinSalesConfig) {
      state.coinSalesConfig = value;
    },
    creditCardInfo(state, value: CreditCardInfo) {
      state.creditCardInfo = value;
    },
    setPaymentToken(state, value: string) {
      state.paymentToken = value;
    }
  },
  actions: {
    async initCoinHistoryInfo({ commit }) {
      const data = await CoinService.getCoinHistory();
      const result = new CoinHistoryInfo(data);
      commit('coinHistoryInfo', result);
      return result;
    },
    async initCoinDistributionInfo({ commit }) {
      const data = await CoinService.getCoinDistribution();
      const result = new CoinHistoryInfo(data);
      commit('coinDistributionInfo', result);
      return result;
    },
    async initCoinSalesConfigInfo({ commit }) {
      const data = await CoinService.getCoinSalesConfig();
      const result = new CoinSalesConfig(data);
      commit('coinSalesConfigInfo', result);
      return result;
    },
    async paycoApprovalCoin({ commit }, { productSeq, paymentType }) {
      const data = await CoinService.paycoApproval(productSeq, paymentType);
      return data;
    },
    async paycoPurchased({ commit }, params) {
      const data = await CoinService.paycoPurchased(params);
      return data;
    },
    async paymentReserve({ commit }, { productSeq, paymentType }) {
      const data = await CoinService.paymentReserve(productSeq, paymentType);
      return data;
    },
    async otherPaymentCancel({ commit }, serviceOrderId) {
      const data = await CoinService.otherPaymentCancel(serviceOrderId);
      return data;
    },
    async paypalPaymentExecute({ commit }, params) {
      const data = await CoinService.paypalPaymentExecute(params);
      return data;
    },
    async stripePaymentFinish({ commit }, serviceOrderId) {
      const data = await CoinService.stripePaymentFinish(serviceOrderId);
      return data;
    },
    async creditCardInfo({ commit }) {
      const data = await CoinService.getCreditCardInfo();
      const result = new CreditCardInfo(data);
      commit('creditCardInfo', result);
    },
    async getCredit4gToken(
      { commit },
      { cardNumber, cardExpire, securityCode, isSave, cardId }
    ) {
      const data = await CoinService.getCreditTokenApiKey();
      let tokenResult = await CoinService.getCredit4gToken(
        cardNumber,
        cardExpire,
        securityCode,
        data.creditApiToken
      );
      if (tokenResult.status === 'success') {
        try {
          if (isSave) {
            await CoinService.registerCreditCard(
              tokenResult.token,
              securityCode
            );
            tokenResult = await CoinService.getCredit4gToken(
              cardNumber,
              cardExpire,
              securityCode,
              data.creditApiToken
            );
          }
        } catch (e) {
          //
        }
      }
      return tokenResult;
    },
    async registCreditCard({ commit }, { mdkToken, securityCode }) {
      await CoinService.registerCreditCard(mdkToken, securityCode);
    },
    async creditCardExcute({ state }, { mdkToken, productSeq, cardId }) {
      const result = await CoinService.creditCardExcute(
        state.paymentToken,
        mdkToken,
        productSeq,
        cardId
      );
      return result;
    },
    async carrierReserve({ commit }, { productSeq, paymentType }) {
      const data = await CoinService.carrierReserve(paymentType, productSeq);
      return data;
    },
    async paypayReserve({ commit }, productSeq) {
      const data = await CoinService.paypayReserve(productSeq);
      return data;
    },
    async japanPaymentResult({ commit }, { resultStatus, serviceOrderId }) {
      const data = await CoinService.japanPaymentResult(
        resultStatus,
        serviceOrderId
      );
      return data;
    },
    async createPaymentToken({ commit }) {
      const { transaction } = await CoinService.getTransaction();
      commit('setPaymentToken', transaction.token);
    }
  }
};
