export interface History {
  price: number;
  title: string;
  createAt: string;
}

export class CoinHistoryInfo {
  history: History[];
  constructor(props) {
    this.history = props.history || [];
  }
}

export interface CoinSales {
  id: number;
  type: string;
  description: string;
  price: number;
  unit: string;
  purchasedAmount: number;
  freeAmount: number;
}

export interface CreditCardToken {
  creditApiToken: string;
}

export interface CreditCard {
  cardExpire: string;
  cardId: string;
  cardNumber: string;
  defaultCard: string;
}

export class CreditCardInfo {
  cardInfo: CreditCard[];
  constructor(props) {
    this.cardInfo = props.cardInfo || [];
  }
}

export interface Credit4gToken {
  token: string;
  status: string;
  code: string;
  message: string;
}

export class CoinSalesConfig {
  sales: CoinSales[];
  constructor(props) {
    this.sales = props.sales || [];
  }
}

export interface CoinToken {
  transaction: {
    reserveOrderNo: string;
    orderSheetUrl: string;
    token: string;
  };
}

export interface CoinTxtInfo {
  price?: number;
  originalPrice?: number;
  coinBackPrice?: number;
  rentalPeriod?: number;
}
