import { Module } from 'vuex';
import { RootState } from '@/store/index';

export interface SingularStoreTypes {
	singular;
}

export const SingularStore: Module<SingularStoreTypes, RootState> = {
	namespaced: true,
	state: {
		singular: null
	},
	getters: {
		singular: (state) => state.singular
	},
	mutations: {
		singular: (state, value) => {
			state.singular = value;
		}
	}
}