import { render, staticRenderFns } from "./UserMenu.vue?vue&type=template&id=8572d882&scoped=true&"
import script from "./UserMenu.vue?vue&type=script&lang=ts&"
export * from "./UserMenu.vue?vue&type=script&lang=ts&"
import style0 from "./UserMenu.vue?vue&type=style&index=0&id=8572d882&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8572d882",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog});
