var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('a',{on:{"click":function($event){return _vm.gotoContentHome({
        contentType: _vm.content.type,
        contentId: _vm.content.id
      })}}},[_c('card-thumbnail',{attrs:{"content":_vm.content}}),_c('div',{staticClass:"thumb_info"},[_c('div',{staticClass:"tit_info",class:{
          tit_info_type:
            _vm.content.restrictedAge &&
            (_vm.content.badge ||
              _vm.content.status === 'event' ||
              _vm.content.status === 'started' ||
              _vm.content.status === 'updated' ||
              _vm.content.status === 'completed')
        }},[(_vm.content.status && _vm.content.status === 'suspend')?_c('badge-hiatus',{attrs:{"badge":_vm.content.badge,"status":_vm.content.status}}):_vm._e(),_c('badge',{attrs:{"badge":_vm.content.badge,"status":_vm.content.status}}),(_vm.$isKo && _vm.content.restrictedAge)?_c('icon-age',{attrs:{"restrictedAge":_vm.content.restrictedAge}}):_vm._e(),_c('strong',{staticClass:"tit_comic"},[_vm._v(_vm._s(_vm.content.name))])],1),_c('span',{staticClass:"txt_info"},[_vm._v(_vm._s(_vm.content.getNovelType() ? _vm.$t('novels') : '')),(_vm.content.getNovelType() && _vm.content.getAuthor(1).length > 0)?_c('span',{staticClass:"txt_bar"}):_vm._e(),_vm._v(_vm._s(_vm.content.getAuthor()))]),_c('span',{staticClass:"txt_viewer"},[_c('span',{staticClass:"ico_viewer"},[_vm._v("Count")]),_vm._v(_vm._s(_vm.content.getTotalView(_vm.$locale)))])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }