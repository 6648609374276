import { ContentInfo, Page, TopBanner } from '@/models';

export interface Event {
  title: string;
  description: string;
  imageUrl: string;
  permanent: boolean;
  periodRemained: number;
  urlScheme: string;
  urlHttp: string;
  mature: boolean;
  sort: number;
}

export interface EventApiInfo {
  contentCategory: string;
  contentCatalogId: string;
}

export class EventInfo {
  events: Event[];
  constructor(props) {
    this.events = props.events || [];
  }
}

export class EventDetailInfo {
  contents: ContentInfo[];
  page: Page;
  topBanner: TopBanner | undefined;
  constructor(props: EventDetailInfo) {
    this.contents = props.contents;
    this.page = props.page;
    this.topBanner = props.topBanner
      ? new TopBanner(props.topBanner)
      : undefined;
  }
}
