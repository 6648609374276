import Vue from 'vue';
import Vuex from 'vuex';
import { HomeInfo } from '@/models';
import { GlobalStore, GlobalStateInterface } from '@/store/global';
import { ErrorStore, ErrorStateInterface } from '@/store/error';
import { AuthStore, AuthStateInterface } from '@/store/auth';
import { HomeStore, HomeStateInterface } from '@/store/home';
import { I18nStore, I18nStateInterface } from '@/store/i18n';
import { MenuStore, MenuStateInterface } from '@/store/menu';
import { LibraryStore, LibraryStateInterface } from '@/store/library';
import { SearchStore, SearchStateInterface } from '@/store/search';
import createPersistedState from 'vuex-persistedstate';
import { ContentsHomeStateInterface, ContentsHomeStore } from './contents-home';
import {
  ComicViewerStoreInterface,
  ComicViewerStore
} from './viewer/comic-viewer';
import { NovelViewerStore } from './viewer/novel-viewer';
import { CoinStore } from './coin';
import { UIStateInterface, UIStore } from './ui';
import { InboxStore } from './inbox';
import { CommentStore } from './mypage/comment';
import { GenreStore, GenreState } from './genre';
import { InquiryStore } from './mypage/inquiry';
import { MyPageStore, MypageInterface } from './mypage/mypage';
import { EventStore } from './event';
import { PolicyStore } from './policy';
import { SelectPurchaseStore, SelectPurchaseType } from './select-purchase';
import { DialogStore, DialogState } from './dialog';
import { SocialLoginStore } from './social-login';
import { OfferwallStore } from './offerwall';
import {
  ChapterCommentTypes,
  ChapterCommentStore
} from './comment/chapter-comment';
import {
  MagazineComicInterface,
  MagazineComicStore
} from './viewer/magazine-comic';
import { AdultHomeStore, AdultHomeTypes } from './adult-home';
import {
  UserCommentHistoryTypes,
  UserCommentHistoryStore
} from './comment/user-comment-history';
import { NoticeFaqStore } from './mypage/notice-faq';
import { SingularStore, SingularStoreTypes } from './ad-script/singular';
import {
  ChallengeContentTypes,
  ChallengeContentStore
} from './challenge/challenge-content';
import { ChallengeHomeStore } from './challenge/challenge';
import {
  ChallengeChapterCommentStore,
  ChallengeChapterCommentTypes
} from './challenge/challenge-chapter-comment';
import {
  ChallengeViewerTypes,
  ChallengeViewerStore
} from './challenge/challenge-viewer';
import {
  ChallengeReportTypes,
  ChallengeReportStore
} from './challenge/challenge-report';
import {
  ChallengeBookshelfTypes,
  ChallengeBookshelfStore
} from './challenge/challenge-bookshelf';
import {
  ChallengeSearchTypes,
  ChallengeSearchStore
} from './challenge/challenge-search';
import { IntegrateStore } from './integrate/integrate';

Vue.use(Vuex);

/**
 * 모듈간 state 공유
 */
export interface RootState {
  GlobalStore: GlobalStateInterface;
  ErrorStore: ErrorStateInterface;
  AuthStore: AuthStateInterface;
  I18nStore: I18nStateInterface;
  HomeStore: HomeStateInterface<HomeInfo>;
  MenuStore: MenuStateInterface;
  ContentsHomeStore: ContentsHomeStateInterface;
  LibraryStore: LibraryStateInterface;
  ComicViewerStore: ComicViewerStoreInterface;
  UIStore: UIStateInterface;
  SearchStore: SearchStateInterface;
  GenreStore: GenreState;
  SelectPurchaseStore: SelectPurchaseType;
  DialogStore: DialogState;
  MyPageStore: MypageInterface;
  ChapterCommentStore: ChapterCommentTypes;
  MagazineComicStore: MagazineComicInterface;
  AdultHomeStore: AdultHomeTypes;
  UserCommentHistoryStore: UserCommentHistoryTypes;
  SingularStore: SingularStoreTypes;
  ChallengeContentStore: ChallengeContentTypes;
  ChallengeChapterCommentStore: ChallengeChapterCommentTypes;
  ChallengeViewerStore: ChallengeViewerTypes;
  ChallengeReportStore: ChallengeReportTypes;
  ChallengeBookshelfStore: ChallengeBookshelfTypes;
  ChallengeSearchStore: ChallengeSearchTypes;
}

/**
 * 로컬 스토리지와 state 간 데이터 동기화를 위해서 사용
 * 사용량에 따라 퍼포먼스가 떨어지기 때문에 적절한 곳에 사용 권장
 * 사파리브라우저 동기화 버그로 인한 주석 처리
const userState = createPersistedState<RootState>({
  key: 'currentUser',
  paths: ['AuthStore.authentication']
});
 */

const languageState = createPersistedState<RootState>({
  key: 'language',
  paths: ['I18nStore.language']
});

const errorState = createPersistedState<RootState>({
  key: 'errorInfo',
  paths: ['ErrorStore.code', 'ErrorStore.title', 'ErrorStore.message']
});

const store = new Vuex.Store({
  modules: {
    GlobalStore,
    I18nStore,
    ErrorStore,
    AuthStore,
    HomeStore,
    MenuStore,
    LibraryStore,
    ContentsHomeStore,
    ComicViewerStore,
    NovelViewerStore,
    UIStore,
    InboxStore,
    CommentStore,
    GenreStore,
    CoinStore,
    SearchStore,
    InquiryStore,
    MyPageStore,
    EventStore,
    PolicyStore,
    SelectPurchaseStore,
    DialogStore,
    SocialLoginStore,
    OfferwallStore,
    ChapterCommentStore,
    MagazineComicStore,
    AdultHomeStore,
    UserCommentHistoryStore,
    NoticeFaqStore,
    SingularStore,
    ChallengeContentStore,
    ChallengeHomeStore,
    ChallengeChapterCommentStore,
    ChallengeViewerStore,
    ChallengeReportStore,
    ChallengeBookshelfStore,
    ChallengeSearchStore,
    IntegrateStore
  },
  plugins: [languageState, errorState]
});

export default store;
