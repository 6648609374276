








import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'EventBannerList',
  props: {
    element: Object
  },
  setup(props, { emit }) {
    const onClick = () => emit('emitBanner', props.element);
    return {
      onClick
    };
  }
});
