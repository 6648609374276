import Vue from 'vue';
import ko from './ko.json';
import ja from './ja.json';
import en from './en.json';
import zh from './tw.json';
import vi from './vi.json';
import fr from './fr.json';
import de from './de.json';
import VueI18n from 'vue-i18n';
import { dateTimeFormats } from '@/commons/constants/i18n-types';
import {
  LANGUAGE_KEY,
  LANGUAGE_EXPIRES,
  LANGUAGE_PARAM_KEY,
  languageLongMap
} from '@/commons/constants/config-language';
import {
  getLanguageCookie,
  getCookie,
  saveCookie,
  setEnDefault,
  diffLang
} from '@/helpers/cookies';
import { getUrlParameterValue } from '@/services/common/url-parameter.helper';

Vue.use(VueI18n);

const messages = { ko, ja, en, zh, vi, fr, de };

/**
 * 스토어 언어
 */
const getLocale = (): string | undefined => {
  let getLen: string | undefined;
  try {
    getLen =
      getUrlParameterValue(LANGUAGE_PARAM_KEY) ||
      getCookie(LANGUAGE_KEY) ||
      process.env.VUE_APP_LANGUAGE;
    if (!getLen) {
      getLen = getLanguageCookie();
    }
  } catch {
    getLen = getLanguageCookie();
  }
  if (getLen.length === 2) getLen = languageLongMap[getLen];
  const diff: string | undefined = diffLang(
    getLen,
    process.env.VUE_APP_LANGUAGE
  );
  if (diff) getLen = diff;
  getLen = setEnDefault(getLen);
  saveCookie(LANGUAGE_KEY, getLen, { expires: LANGUAGE_EXPIRES });
  return getLen;
};

const i18n = new VueI18n({
  locale: getLocale(),
  fallbackLocale: getLanguageCookie(),
  messages,
  silentTranslationWarn: true,
  dateTimeFormats
});

const t = (value: string): string =>
  i18n.t(value).toString() ? i18n.t(value).toString() : `**${value}**`;
const i18nTxt = (value: string): string =>
  i18n.t(value).toString() ? i18n.t(value).toString() : `**${value}**`;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const i18nTObj = (value: string) => JSON.parse(JSON.stringify(i18n.t(value)));
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const i18nFormatting = (
  value: string,
  format: { [key: string]: string | number }
) => i18n.t(value, format).toString();
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const i18nWrapRuby = (ruby: string, value: string) =>
  ruby.replace(/%@|%d/g, value);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const i18nBreak = (value: string) => i18nTxt(value).split('\n').join('<br />');

export {
  i18n,
  t,
  i18nTxt,
  i18nTObj,
  i18nFormatting,
  i18nWrapRuby,
  i18nBreak,
  getLocale
};
