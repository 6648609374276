import { createAxiosService } from '@/services/common/create-axios.service';

const axiosService = createAxiosService();

const subscribe = (subscription: PushSubscription): Promise<string> => {
  return new Promise<string>(resolve => {
    const url = `/web-push/subscribe`;
    return axiosService
      .post(url, { subscription, userId: sessionStorage.getItem('userId') }, {
        headers: { 'Content-Type': `application/json` }
      })
      .then(res => {
        const { data } = res;
        resolve(data.data);
      })
  });
};

const unsubscribe = (endpoint: string): Promise<string> => {
  return new Promise<string>(resolve => {
    const url = `/web-push/unsubscribe`;
    return axiosService
      .delete(url, { params: { endpoint: endpoint, userId: sessionStorage.getItem('userId') } })
      .then(res => {
        const { data } = res;
        resolve(data.data);
      })
  });
};

const updateSubscription = (subscription: PushSubscription): Promise<string> => {
  return new Promise<string>(resolve => {
    const url = `/web-push/update-subscription`;
    return axiosService
      .put(url, { subscription, userId: sessionStorage.getItem('userId') }, {
        headers: { 'Content-Type': `application/json` }
      })
      .then(res => {
        const { data } = res;
        resolve(data.data);
      })
  });
};

export const WebPushService = {
  subscribe,
  unsubscribe,
  updateSubscription
}