





























import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  onUpdated,
  Ref,
  ref,
  watch
} from '@vue/composition-api';
import MenuItemsNavigator from '@/components/molecules/navigators/MenuItemsNavigator.vue';
import { IconType, ICON_NEXT } from '@/commons/constants/icon-types';
import { MenuItem } from '@/models';
import store from '@/store';

export default defineComponent({
  name: 'TabMenuItems',
  components: {
    MenuItemsNavigator
  },
  props: {
    menuItems: Array,
    selectedItem: String,
    i18nTObj: Object,
    fixed: Boolean,
    parent: String
  },
  setup(props, context) {
    const { getters } = store;
    const pointerEvents: ComputedRef<boolean> = computed(
      () => getters['MenuStore/pointerEvents']
    );
    const selectedItem = computed(() =>
      props.selectedItem ? props.selectedItem : ''
    );
    const menuItem = computed(() =>
      props.menuItems ? (props.menuItems as MenuItem[]) : ([] as MenuItem[])
    );
    const innerSideRef: Ref<HTMLDivElement | null> = ref(null);
    const liRef: Ref<HTMLLinkElement | null> = ref(null);
    const [isPrev, isNext] = [ref(false), ref(false)]; // 좌우 화살표 : true > show, false > hide
    const WIDTH_UNIT = 728; // WIDTH_UNIT: scroll 너비 단위
    const SCROLL_DELAY = 500;
    const depth1 = computed(() => props.parent);

    const getLiLeft = () => {
      let currentIndex = 0;
      menuItem.value.forEach((node, idx) => {
        if (node.code === selectedItem.value) {
          currentIndex = idx;
        }
      });
      if (liRef.value) {
        const el: HTMLLIElement = liRef.value[currentIndex];
        return el.offsetLeft - WIDTH_UNIT / 2 + el.offsetWidth / 2;
      } else {
        return 0;
      }
    };

    const onItemClick = (item: MenuItem) =>
      context.emit('changeMenuItem', item);

    /**
     * 화살표 방향 감지
     */
    const sendDirection = (data: IconType) => {
      const { type } = data;
      const div: HTMLDivElement | null = innerSideRef.value;
      const value =
        type === ICON_NEXT.type ? +(WIDTH_UNIT / 2) : -(WIDTH_UNIT / 2);
      if (div) {
        setScrollPosition(div.scrollLeft + value);
        setTimeout(() => {
          // smooth scroll 딜레이
          checkArrowState();
        }, SCROLL_DELAY);
      }
    };

    /**
     * nava 상태 세팅
     */
    const checkArrowState = () => {
      const div = innerSideRef.value;
      if (!div) {
        return;
      }
      const cWidth = div?.clientWidth;
      const sWidth = div?.scrollWidth;
      if (cWidth >= sWidth) {
        isPrev.value = false;
        isNext.value = false;
      } else {
        isPrev.value = div.scrollLeft !== 0;
        isNext.value = !(div.scrollLeft + WIDTH_UNIT + 1 >= sWidth);
      }
    };

    /**
     * navi scroll 위치세팅
     */
    const setScrollPosition = (movePosition: number) => {
      const div: HTMLDivElement | null = innerSideRef.value;
      div?.scrollTo(movePosition, 0);
      setTimeout(() => {
        // smooth scroll 딜레이
        checkArrowState();
      }, SCROLL_DELAY);
    };

    onUpdated(() => {
      checkArrowState();
    });

    watch(depth1, () => {
      setScrollPosition(0);
    });

    onMounted(() => {
      const width = innerSideRef.value ? innerSideRef.value.scrollWidth : 0;
      if (width > WIDTH_UNIT) {
        const currentScroll: number = getLiLeft();
        setScrollPosition(currentScroll);
      }
    });

    return {
      selectedCode: selectedItem,
      innerSideRef,
      liRef,
      isPrev,
      isNext,
      depth1,
      pointerEvents,
      sendDirection,
      onItemClick
    };
  }
});
