





































































































































import store from '@/store';
import router from '@/router';
import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import IconAge from '@/components/atoms/icons/IconAge.vue';
import IconTypeSpan from '@/components/atoms/icons/IconTypeSpan.vue';
import IconTypeAnchor from '@/components/atoms/icons/IconTypeAnchor.vue';
import ContentsDetail from '@/components/molecules/contents/ContentsDetail.vue';
import { IconType, TYPE_ICONS } from '@/commons/constants/icon-types';
import { VIEW_CHAPTER_TYPE } from '@/commons/constants/content.types';
import { AUTHOR_TYPE_CREATOR } from '@/commons/constants/author-types';
import { ContentInfo, Genre } from '@/models';
import { sendNclick } from '@/services';
import { numToShortForm } from '@/services/common/utils';
import { i18nTxt } from '@/commons/i18n';

export default defineComponent({
  name: 'ContentsIntroModule',
  components: {
    IconAge,
    IconTypeSpan,
    IconTypeAnchor,
    ContentsDetail
  },
  props: {
    content: ContentInfo,
    introTheme: String,
    recommends: Array,
    isSubscribed: Boolean,
    contentType: String,
    totalViews: Number
  },
  setup(props, { emit }) {
    const { getters, dispatch } = store;
    const showDetail: ComputedRef<IconType> = computed(
      () => getters['ContentsHomeStore/showDetail']
    );
    const contentInfo: ComputedRef<ContentInfo> = computed(
      () => props.content as ContentInfo
    );
    const mapContentType: ComputedRef<'key_visual' | 'cover'> = computed(
      () => getters['ContentsHomeStore/mapContentType']
    );
    const restrictedAge: ComputedRef<number | undefined> = computed(
      () => contentInfo.value?.restrictedAge
    );
    const lastReadId: ComputedRef<number | undefined> = computed(() =>
      contentInfo.value?.getLastReadChapterId()
    );
    const purchaseInfoLabel = computed(() => {
      let purchaseInfo: string[] = [];
      if (contentInfo.value.isIncludePurchaseChapter()) {
        purchaseInfo.push(i18nTxt('collect'));
      }
      if (contentInfo.value.isIncludeRentableChapter()) {
        purchaseInfo.push(i18nTxt('rental'));
      }
      return purchaseInfo.join('/');
    });

    const sendGotoChapter = () => {
      emit('emitGotoChapter', lastReadId.value);
      sendNclick(
        router.currentRoute.path,
        'content.' + (lastReadId.value ? 'continue' : 'chapter.first'),
        '' + contentInfo.value?.id,
        '' + (lastReadId.value || 1),
        ''
      );
    };
    const sendOpen = () => {
      dispatch('ContentsHomeStore/showDetailContent');
      if (showDetail.value.type === 'open')
        sendNclick(
          router.currentRoute.path,
          'content.information',
          '' + contentInfo.value?.id,
          '',
          ''
        );
    };
    const sendShare = () =>
      emit('emitShareDialog', {
        content: contentInfo.value
      });

    const sendSubscribed = () => emit('emitSubscribed', {});
    const sendGotoGenre = (genre: Genre) => emit('emitGotoGenre', genre);

    return {
      sendGotoChapter,
      sendOpen,
      sendShare,
      sendSubscribed,
      sendGotoGenre,
      numToShortForm,
      TYPE_ICONS,
      VIEW_CHAPTER_TYPE,
      showDetail,
      mapContentType,
      restrictedAge,
      lastReadId,
      AUTHOR_TYPE_CREATOR,
      contentInfo,
      purchaseInfoLabel
    };
  }
});
