import { ERROR_CODES } from '@/commons/constants/error-codes';
import { CODE_TYPE } from '@/commons/constants/code-type';
import Store from '@/store/index';
import { ValidateCheked } from '@/models/index';

const onresize = (): {
  browserMode: string;
  browserWidth: number;
  browserHeight: number;
} => {
  const params = {
    browserMode: document.body.clientWidth > 767 ? 'pc' : 'mobile',
    browserWidth: document.body.clientWidth,
    browserHeight: document.body.clientHeight
  };
  Store.dispatch('GlobalStore/browserState', params);
  return params;
};

/** env */
const envPhase = (env: string, str: string): boolean =>
  env === str ? true : false;

/**
 * http status 체크
 */
const CheckStatusOk = (accessCode: number): boolean => {
  if (accessCode !== ERROR_CODES.CODE_200) return false;
  else return true;
};

/**
 * Global dimmed
 */
const dimmed = (state: boolean): void => {
  Store.commit('GlobalStore/setDimmed', state);
};

/**
 * bg_dimmed2
 */
const dimmedDeep = (state: boolean): void => {
  Store.commit('GlobalStore/setDimmedDeep', state);
};

/**
 * Global Search popup show/hide
 */
const ClassesHeader = (addClass: string, removeClass?: string): void => {
  const header = document.getElementById('header');
  if (addClass) header?.classList.add(addClass);
  if (removeClass) header?.classList.remove(removeClass);
};

/**
 * 모바일일 경우 서브페이지 헤더 hidden 클래스 추가
 */
const ClassesHidden = (state: boolean, classes: string): void => {
  const body = document.body.classList;
  state ? body.add(classes) : body.remove(classes);
};

/** 비회원 접근시 라우팅 중지 */
const routerNextAborted = (errorMessage: string): boolean => {
  return errorMessage.indexOf('aborted') != -1 ? true : false;
};

/** 동일한 URL인지 체크 */
const isSameUrl = (errorName: string): boolean =>
  errorName === 'NavigationDuplicated' ? true : false;

const isEmptyObject = (param: Function | Object): boolean => {
  return Object.keys(param).length === 0 && param.constructor === Object;
};

const isPC = (): boolean => {
  const mode =
    Store.getters['GlobalStore/getBrowserState'].browserMode ||
    onresize().browserMode;
  return mode === 'pc' ? true : false;
};

const isIPhone = (): boolean => {
  return navigator.userAgent.indexOf('iPhone') != -1;
};

const isIPad = (): boolean => {
  return navigator.userAgent.indexOf('iPad') != -1;
};

const isIPadOver13 = (): boolean => {
  return navigator.maxTouchPoints === 5;
};

const isIPod = (): boolean => {
  return navigator.userAgent.indexOf('iPod') != -1;
};

const isMac = (): boolean => {
  return navigator.platform.indexOf('Mac') != -1;
};

const isIOS = (): boolean => {
  let isIOS = false;
  if (isIPhone()) isIOS = true;
  if (isIPad()) isIOS = true;
  if (isIPod()) isIOS = true;
  if (isMac() && isIPadOver13()) isIOS = true;
  return isIOS;
};

const isAOS = (): boolean => {
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf('android') > -1;
  return isAndroid;
};

const isWeb = (): string => {
  return isAOS()
    ? CODE_TYPE.OS.ANDROID
    : isIOS()
    ? CODE_TYPE.OS.IOS
    : CODE_TYPE.OS.PCWEB;
};

const isIE = (): boolean => {
  const currentBrowser = navigator.userAgent.toLowerCase();
  if (currentBrowser.indexOf('msie') > -1) {
    const reg = new RegExp('msie ([0-9]{1,})(\\.{0,}[0-9]{0,1})');
    if (reg.exec(currentBrowser) !== null) {
      const version = Number(RegExp.$1);
      if (version <= 10) return true;
    }
  }

  return false;
};

/** 폼 전체 입력 확인 */
const isFormPass = (exc: string[], obj: Object): boolean => {
  const inc: string[] = [];
  for (const k in obj) {
    if (!exc.includes(k) && !obj[k]) {
      inc.push(k);
    }
  }
  return inc.length > 0 ? false : true;
};

/** 숫자만입력 */
const keyupOnlyNumber = (target: HTMLInputElement, maxLen?: number): string => {
  const regexp = /[^0-9]/g;
  target.value = target.value.replace(regexp, '');
  if (maxLen && target.value.length > maxLen) {
    target.value = target.value.slice(0, target.maxLength);
  }
  return target.value;
};

const dateFormat = (
  time: number,
  type: string
): { time: number; type: string } => {
  return { time, type };
};

const dateConvert = (
  at: string | undefined,
  views?: string
): { time: number | string; type: string } => {
  if (!at) return { time: 0, type: 'sec' };

  const timeToday = Number(new Date());
  const timeEpoch = Number(new Date(at));
  const timeTostr = Math.abs(timeToday - timeEpoch).toString();
  const difSec = Number(timeTostr.substring(0, timeTostr.length - 3)); // 초 반환
  const min1 = 60;
  const hour1 = min1 * 60; // 3600초
  const day1 = hour1 * 24; // 86400초
  const day30 = day1 * 30; // 2592000초

  switch (true) {
    case difSec < 60:
      return dateFormat(1, 'sec'); // 1분 미만
    case difSec <= hour1:
      return dateFormat(Math.floor(difSec / min1), 'min'); // 1분~59분
    case difSec <= day1:
      return dateFormat(Math.floor(difSec / hour1), 'hour'); // 1시~23시
    case views && views === 'rented':
      return dateFormat(Math.floor(difSec / day1), 'day'); // 1일 이후
    case views && views === 'expired':
      return dateFormat(Math.ceil(difSec / day1), 'day'); // 1일 이후, 올림처리
    case difSec <= day30:
      return dateFormat(Math.floor(difSec / day1), 'day'); // 1일~30일
    default:
      return dateFormat(timeEpoch, 'date'); // 31일 이후
  }
};

const numToShortForm = (num: number, locale: string): string => {
  if (locale) {
    switch (locale.toLocaleLowerCase()) {
      case 'ko':
        return numToShortFormKr(num);
      case 'ja':
        return numToShortFormJp(num);
      case 'en':
      default:
        return numToShortFormEn(num);
    }
  } else {
    return numToShortFormEn(num);
  }
};

const numToShortFormKr = (num: number) => {
  if (num < 10000) {
    return num + '';
  } else if (num >= 10000 && num < 10000000) {
    // 만
    const result = Math.floor(num) / 10000 + '';
    if (Math.floor(num) % 10000 === 0) {
      return result + '.0만';
    } else {
      return result.substr(0, result.indexOf('.') + 2) + '만';
    }
  } else if (num >= 10000000) {
    // 억
    const result = Math.floor(num) / 100000000 + '';
    if (Math.floor(num) % 100000000 == 0) {
      return result + '억';
    } else {
      return result.substr(0, result.indexOf('.') + 2) + '억';
    }
  }
  return num + '';
};

const numToShortFormJp = (num: number) => {
  if (num < 10000) {
    return num + '';
  } else if (num >= 10000 && num < 10000000) {
    // 만
    const result = Math.floor(num) / 10000 + '';
    if (Math.floor(num) % 10000 === 0) {
      return result + '.0万';
    } else {
      return result.substr(0, result.indexOf('.') + 2) + '万';
    }
  } else if (num >= 10000000) {
    // 억
    const result = Math.floor(num) / 100000000 + '';
    if (Math.floor(num) % 100000000 == 0) {
      return result + '億';
    } else {
      return result.substr(0, result.indexOf('.') + 2) + '億';
    }
  }
  return num + '';
};

const numToShortFormEn = (num: number): string => {
  if (num < 1000) {
    return num + '';
  } else if (num >= 1000 && num < 1000000) {
    const result = Math.floor(num) / 1000 + '';
    if (Math.floor(num) % 1000 == 0) {
      return result + '.0K';
    } else {
      return result.substr(0, result.indexOf('.') + 2) + 'K';
    }
  } else if (num >= 1000000) {
    const result = Math.floor(num) / 1000000 + '';
    if (Math.floor(num) % 1000000 == 0) {
      return result + '.0M';
    } else {
      return result.substr(0, result.indexOf('.') + 2) + 'M';
    }
  }
  return num + '';
};

/**
 * array -> , 추가된 string 변환
 * @param result
 * @param len
 * @returns
 */
const arrToCommaStr = (result: Array<string>, len?: number): string => {
  if (len) {
    result = result.splice(0, len);
  }
  return result.length > 0 ? result.join(', ') : '';
};

/**
 * 특정 특수기호는 인코딩인 안되는 현상으로 추가변환 후 인코딩 값 반환
 */
const superEncodeURI = (str: string): string => {
  return encodeURIComponent(str).replace(/[*!()-]/gi, function (c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
};

/**
 * 요일 계산
 */
const getMonth = (strDate: Date): number => {
  const month = strDate.getMonth() + 1;
  return month;
};
const getDate = (strDate: Date, i18n: Object): string => {
  const week = [
    i18n['sun'],
    i18n['mon'],
    i18n['tue'],
    i18n['wed'],
    i18n['thu'],
    i18n['fri'],
    i18n['sat']
  ];
  const dayOfWeek = week[new Date(strDate).getDay()];
  return dayOfWeek;
};

/** Object.entries IE11 대응 */
const entriesPolyFill = (obj: Object): string | Object[][] => {
  const keys = Object.keys(obj);
  const keyValuePairs = keys.map((key) => {
    const value = obj[key];
    return [key, value];
  });
  return keyValuePairs;
};

/** 유효성 검사 */
const validateCheked = async (opt: ValidateCheked): Promise<Object> => {
  const {
    entriesArray,
    compareTarget = null,
    confirmedTarget = null,
    validate,
    schema
  } = opt;
  const entries = Object.entries
    ? Object.entries(entriesArray)
    : entriesPolyFill(entriesArray);
  const errorMsg = {};
  for (const [key, value] of entries) {
    const values =
      key !== confirmedTarget
        ? value
        : { value, isConfirmed: compareTarget && entriesArray[compareTarget] };
    const res: { errors: number; valid: string } = await validate(
      compareTarget ? values : value,
      schema[key],
      { skipIfEmpty: false }
    );
    if (res.errors[0]) errorMsg[key] = res.errors[0];
  }

  return errorMsg;
};

window.addEventListener('resize', onresize);

const imgToCanvas = (
  img: HTMLImageElement
): {
  canvas: HTMLCanvasElement;
  clientWidth: number;
  clientHeight: number;
} | null => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const RATIO = 2;
  if (ctx) {
    const { clientWidth, clientHeight } = img;
    canvas.style.width = clientWidth + 'px';
    canvas.style.height = clientHeight + 'px';
    canvas.width = clientWidth * RATIO;
    canvas.height = clientHeight * RATIO;
    ctx.scale(RATIO, RATIO);
    ctx.drawImage(img, 0, 0, clientWidth, clientHeight);
    return {
      canvas,
      clientWidth,
      clientHeight
    };
  } else {
    return null;
  }
};

const pad = (nn: number | string): string => {
  try {
    const n = nn + '';
    return n.length >= 2 ? n : new Array(2 - n.length + 1).join('0') + n;
  } catch (e) {
    return nn + '';
  }
};

/**
 * PC에 설정 된 표준 시간대를 기준으로 파싱
 * @param format Date
 */
const dateArray = (at: Date, lang: string): string => {
  return at.toLocaleString(lang, {
    hour12: true,
    month: 'short',
    weekday: 'short',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });
};

/** http response status compare */
const statusCompare = (statusCode: number, suceesCode: number): boolean =>
  statusCode === suceesCode;

const convertDate = (date?: string | number | Date): string => {
  const d = date ? new Date(date) : new Date();
  return d.toLocaleDateString(Store.getters['I18nStore/getCookieLanguage'], {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

const arraySort = (array, property: string) =>
  array.sort((a, b) =>
    b[property] > a[property] ? 1 : a[property] > b[property] ? -1 : 0
  );

export {
  CheckStatusOk,
  envPhase,
  dimmed,
  dimmedDeep,
  ClassesHeader,
  ClassesHidden,
  routerNextAborted,
  isSameUrl,
  isPC,
  isEmptyObject,
  isAOS,
  isIOS,
  isWeb,
  isIE,
  isIPhone,
  isFormPass,
  keyupOnlyNumber,
  numToShortForm,
  arrToCommaStr,
  superEncodeURI,
  dateConvert,
  getMonth,
  getDate,
  onresize,
  entriesPolyFill,
  validateCheked,
  imgToCanvas,
  statusCompare,
  convertDate,
  pad,
  dateArray,
  numToShortFormKr,
  numToShortFormJp,
  numToShortFormEn,
  arraySort
};
