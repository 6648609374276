









































































import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  onUnmounted,
  Ref,
  ref,
  watch
} from '@vue/composition-api';
import store from '@/store';
import SliderText from '@/components/atoms/SliderText.vue';
import { gotoLinkPage, isPC, sendNclick } from '@/services';
import { Element, TopBanner } from '@/models';
import {
  getForwardPath
} from '@/services/contents/contents.helper';

export default defineComponent({
  name: 'Carousel',
  components: {
    SliderText
  },
  props: {
    topBanner: Object
  },
  setup(props) {
    const { commit } = store;
    const topBanner: ComputedRef<TopBanner> = computed(
      () => props.topBanner as TopBanner
    );
    const slides: ComputedRef<Element[]> = computed(() =>
      topBanner.value?.elements ? topBanner.value?.elements : []
    );
    const topslideRef: Ref<HTMLDivElement | null> = ref(null);
    const isMargin: Ref<boolean> = ref(false);
    const calcHeight: Ref<number> = ref(485);
    const currentSlide = ref(0);
    const slideRef: Ref<null> = ref(null);
    // const themeColor = ref(slides.value[currentSlide.value]);
    const themeColor = computed(() => {
      try {
        return slides.value[currentSlide.value].themeColor;
      } catch (e) {
        return '#CED4DA';
      }
    });

    /**
     * topslideRef 높이 저장 (홈메뉴 위치세팅 관련)
     */
    const saveSlideHeight = () =>
      commit('UIStore/topslideHeight', topslideRef.value?.clientHeight);

    /**
     * 리사이징에 따른 배너 높이 계산 & 세팅
     */
    const handleResize = () => {
      if (topslideRef.value && topslideRef.value.clientWidth < 753) {
        calcHeight.value = topslideRef.value.clientWidth;
      } else {
        calcHeight.value = 384;
      }
    };

    const sendClick = (e) => {
      try {
        const { target } = e;
        if (target && (target.classList.item(0) === 'q-btn__content' || target.parentElement.classList.item(0) === 'q-btn__content')) {
          // 좌우 arrow 이벤트 skip
          return;
        }
        const selected: Element = slides.value[currentSlide.value];
        if (!selected.urlHttp) return;
        const { internal, path } = getForwardPath(selected.urlHttp);
        if (internal) {
          gotoLinkPage(path);
        } else {
          window.location.href = path;
        }
        sendNclick(
          'home',
          'home.card',
          '' + selected.id,
          '',
          topBanner.value.elementUiType +
            '&sectionid=' +
            topBanner.value.id +
            '&id=' +
            selected.id
        );
      } catch (e) {
        //
      }
    };

    const resetSlideIndex = () => {
      currentSlide.value = 0;
    };

    const sendTransition = (newVal: number, oldVal: number) => {
      const dir = newVal - oldVal < 0 ? 'left' : 'right';
      if (topslideRef.value) {
        const icons = topslideRef.value.querySelectorAll(
          '.q-carousel__navigation-icon'
        );
        const newIcon = icons[newVal];
        const oldIcon = icons[oldVal];

        newIcon.classList.add(`slidein_${dir}`);
        oldIcon.classList.add(`slideout_${dir}`);
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', saveSlideHeight);

    watch(slides, () => {
      resetSlideIndex();
    });

    onMounted(() => {
      saveSlideHeight();
      handleResize();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', saveSlideHeight);
    });

    return {
      isPC,
      currentSlide,
      autoplay: ref(true),
      title: ref(''),
      text: ref(''),
      slides,
      topslideRef,
      isMargin,
      calcHeight,
      themeColor,
      sendClick,
      sendTransition,
      slideRef
    };
  }
});
