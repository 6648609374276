import { ContentInfo, ContentsItemInfo, Menu, MenuHolder, MenuItem, Page } from '@/models/index';
import { ContentsApiService } from '@/services/contents/contents.api';
import { MenuApiSerivce } from '@/services/menu/menu.api';
import { MenuHelperService } from '@/services/menu/menu.helpers';
import { Module } from 'vuex';
import { RootState } from '.';

export interface MenuStateInterface {
  menuItems: Array<MenuItem>;
  menuItem: MenuItem;
  menuHolder: MenuHolder;
  contentList: Array<ContentInfo>;
  page: Page;
  pointerEvents: Boolean;
}

export const MenuStore: Module<MenuStateInterface, RootState> = {
  namespaced: true,
  state: {
    menuItems: [],
    menuItem: {} as MenuItem,
    menuHolder: {} as MenuHolder,
    contentList: [],
    page: {} as Page,
    pointerEvents: true
  },
  mutations: {
    menuItems(state: MenuStateInterface, value: Array<MenuItem>) {
      state.menuItems = value;
    },
    menuItem(state: MenuStateInterface, value: MenuItem) {
      state.menuItem = value;
    },
    menuHolder(state: MenuStateInterface, value: MenuHolder) {
      state.menuHolder = value;
    },
    contentList(state: MenuStateInterface, value: ContentInfo[]) {
      state.contentList = value;
    },
    page(state: MenuStateInterface, value: Page) {
      state.page = value;
    },
    pointerEvents(state: MenuStateInterface, value: boolean) {
      state.pointerEvents = value;
    }
  },
  getters: {
    menuItems: (state: MenuStateInterface) => state.menuItems,
    menuItem: (state: MenuStateInterface) => state.menuItem,
    menuHolder: (state: MenuStateInterface) => state.menuHolder,
    contentList: (state: MenuStateInterface) => state.contentList,
    page: (state: MenuStateInterface) => state.page,
    pointerEvents: (state: MenuStateInterface) => state.pointerEvents
  },
  actions: {
    async initContents({ commit, getters }, { apiPath, pageState }) {
      try {
        commit('pointerEvents', false);
        const result: ContentsItemInfo = await ContentsApiService.getContentsList(apiPath, pageState);
        let newContents = result.contents.map(node => new ContentInfo(node));
        if (pageState.pageNo !== 0) {
          newContents = getters.contentList.concat(newContents);
        }
        commit('contentList', newContents);
        commit('page', result.page);
      } catch (e) {
        console.error(`MenuState initContents err > ${e}`);
        commit('contentList', []);
      } finally {
        commit('pointerEvents', true);
      }
    },
    async initMenuItems({ commit }, { contentCategory, dep1Param, currentItemCode }) {
      try {
        // const language: string = rootGetters['I18nStore/getCookieLanguage'];
        const result = await MenuApiSerivce.getMenu(contentCategory, dep1Param);
        const menu = new Menu(result.menu);
        let selectItem: MenuItem;
        if (dep1Param === 'daily') {
          selectItem = MenuHelperService.selectDailyItems(menu.items, currentItemCode) as MenuItem;
        }
        else {
          selectItem = MenuHelperService.selectItems(menu.items, currentItemCode) as MenuItem;
        }
        commit('menuHolder', menu.holder);
        commit('menuItems', menu.items);
        commit('menuItem', selectItem);
        return {
          contentCategory,
          depth1: menu.holder.code,
          depth2: selectItem.code,
          apiPath: selectItem.apiPath
        }
      } catch (e) {
        console.error(`initMenuItems err: ${e}`);
      }
    },
    async initAdultMenuItems({ commit }, { contentCategory, currentItemCode }) {
      const result = await MenuApiSerivce.getMenu(contentCategory, 'adult');
      const menu = new Menu(result.menu);
      const selectItem: MenuItem = MenuHelperService.selectItems(menu.items, currentItemCode) as MenuItem;
      commit('menuHolder', menu.holder);
      commit('menuItems', menu.items);
      commit('menuItem', selectItem);
      return {
        contentCategory,
        depth1: menu.holder.code,
        depth2: selectItem.code,
        apiPath: selectItem.apiPath,
      }
    },
  }
};
