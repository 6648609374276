









import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'GenreThumbnail',
  props: {
    element: Object
  },
  setup(props, context) {
    const gotoGenre = () => context.emit('gotoGenre', props.element);
    return {
      gotoGenre
    };
  }
});
