export const translations = (
  keys: Object | undefined,
  plural: number | string
): string => {
  if (!keys) return '';

  return String(plural).length === 1
    ? keys['one'] || keys['other']
    : keys['other'] || keys['one'];
};

export const PluralHelperService = {
  translations
};
