import store from '@/store';
import router, { alertGoToLogin } from '@/router';
import { ERROR_CODES } from '@/commons/constants/error-codes';
import { DEFAULT_PAGE_STATE } from '@/commons/constants/challenge/challenge-properties';
import { getErrorDialogOptions } from '@/services';

export const challengeRouter = [
  {
    path: '/challenge',
    component: () => import('@/views/challenge/Challenge.vue'),
    meta: { isChallenge: true },
    beforeEnter: (to, from, next) => {
      if (checkLocale()) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: '',
        name: 'ChallengeHome',
        meta: { tab: true },
        component: () => import('@/views/challenge/ChallengeHome.vue'),
        beforeEnter: async (to, from, next) => {
          await store.dispatch('ChallengeHomeStore/getChallengeHome');
          await store.dispatch('ChallengeHomeStore/getChallengeContents', {});
          next();
        }
      },
      {
        path: 'ranking/:rankingType?',
        name: 'ChallengeRanking',
        props: true,
        meta: { tab: true },
        component: () => import('@/views/challenge/ChallengeRanking.vue')
        // beforeEnter: async (to, from, next) => {
        //   const rankingType = to.params.rankingType;
        //   await store.dispatch('ChallengeHomeStore/getChallengeRanking', {
        //     rankingType
        //   });
        //   next();
        // }
      },
      {
        path: 'genre/:genreId?',
        name: 'ChallengeGenre',
        props: true,
        meta: { tab: true },
        component: () => import('@/views/challenge/ChallengeGenre.vue')
        // beforeEnter: async (to, from, next) => {
        //   const genreId = to.params.genreId;
        //   await store.dispatch('ChallengeHomeStore/getChallengeGenre', {
        //     genreId
        //   });
        //   next();
        // }
      },
      {
        path: 'comic/:contentId',
        name: 'ChallengeContent',
        meta: { tab: true },
        component: () => import('@/views/challenge/ChallengeContent.vue'),
        beforeEnter: async (to, from, next) => {
          try {
            const { contentId } = to.params;
            await store.dispatch('ChallengeContentStore/initContent', {
              contentId
            });
            next();
          } catch (e: any) {
            switch (e.code) {
              // case ERROR_CODES.CODE_428:	// 성인 on/off 권한
              // case ERROR_CODES.CODE_451:	// 성인 인증
              case ERROR_CODES.CODE_401: // 로그인 오류
                alertGoToLogin(to, from, next);
                return;
              case ERROR_CODES.CODE_404:
              case ERROR_CODES.CODE_303:
              default:
                errorDialogHandler(store.dispatch, { result: e });
                break;
            }
          }
        }
      },
      {
        path: 'comment-list/:contentId/:chapterId',
        name: 'ChallengeChapterComment',
        component: () =>
          import('@/views/challenge/ChallengeChapterComment.vue'),
        beforeEnter: async (to, from, next) => {
          const { contentId, chapterId } = to.params;
          await store.dispatch('ChallengeChapterCommentStore/initComments', {
            contentId,
            chapterId
          });
          next();
        }
      },
      {
        path: 'comment-write/:contentId/:chapterId',
        name: 'ChallengeWriteComment',
        component: () => import('@/views/challenge/ChallengeWriteComment.vue'),
        beforeEnter: async (to, from, next) => {
          const isAuth = store.getters['AuthStore/isAuth'];
          if (isAuth) {
            next();
          } else {
            alertGoToLogin(to, from, next);
          }
        }
      },
      {
        path: 'comic/:contentId/:chapterId',
        name: 'ChallengeViewer',
        component: () => import('@/views/challenge/ChallengeViewer.vue'),
        beforeEnter: async (to, from, next) => {
          const { contentId, chapterId } = to.params;
          try {
            await store.dispatch('ChallengeViewerStore/initChapter', {
              contentId,
              chapterId
            });
            next();
          } catch (e: any) {
            switch (e.code) {
              case ERROR_CODES.CODE_401: // 비로그인
                alertGoToLogin(to, from, next);
              case ERROR_CODES.CODE_404:
              case ERROR_CODES.CODE_303:
              default:
                next(`/challenge/comic/${contentId}`);
                break;
            }
          }
        }
      },
      {
        path: 'bookshelf',
        name: 'ChallengeBookshelf',
        redirect: { name: 'ChallengeSubscribe' },
        component: () => import('@/views/challenge/ChallengeBookshelf.vue'),
        meta: { authRequired: true, isChallenge: true },
        beforeEnter: (to, from, next) => {
          if (checkLocale()) {
            next();
          } else {
            next('/');
          }
        },
        children: [
          {
            path: 'subscribe',
            name: 'ChallengeSubscribe',
            meta: { tabBookshelf: true },
            component: () => import('@/views/challenge/ChallengeSubscribe.vue'),
            beforeEnter: async (to, from, next) => {
              await store.dispatch(
                'ChallengeBookshelfStore/initSubscriptions',
                DEFAULT_PAGE_STATE
              );
              next();
            }
          },
          {
            path: 'history',
            name: 'ChallengeHistory',
            meta: { tabBookshelf: true },
            component: () => import('@/views/challenge/ChallengeHistory.vue'),
            beforeEnter: async (to, from, next) => {
              await store.dispatch(
                'ChallengeBookshelfStore/initHistorys',
                DEFAULT_PAGE_STATE
              );
              next();
            }
          }
        ]
      },
      {
        path: '/challenge/search/:keyword',
        name: 'ChallengeSearch',
        component: () => import('@/views/challenge/ChallengeSearch.vue'),
        beforeEnter: async (to, from, next) => {
          const { keyword } = to.params;
          await store.dispatch('ChallengeSearchStore/initSearch', keyword);
          next();
        }
      }
    ]
  },
  {
    path: '/event/contest',
    component: () => import('@/views/event/contest/index.vue'),
    beforeEnter: (to, from, next) => {
      if(checkLocale()) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/event/contest/115',
    component: () => import('@/views/event/contest/115/index.vue'),
    beforeEnter: (to, from, next) => {
      if(checkLocale()) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/event/contest/115/result',
    component: () => import('@/views/event/contest/115/result.vue'),
    beforeEnter: (to, from, next) => {
      if(checkLocale()) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/event/contest/117',
    component: () => import('@/views/event/contest/117/index.vue'),
    beforeEnter: (to, from, next) => {
      if(checkLocale()) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/event/contest/117/result',
    component: () => import('@/views/event/contest/117/result.vue'),
    beforeEnter: (to, from, next) => {
      if(checkLocale()) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/event/contest/118',
    component: () => import('@/views/event/contest/118/index.vue'),
    beforeEnter: (to, from, next) => {
      if(checkLocale()) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/event/contest/118/result',
    component: () => import('@/views/event/contest/118/result.vue'),
    beforeEnter: (to, from, next) => {
      if(checkLocale()) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/event/contest/116',
    component: () => import('@/views/event/contest/116/index.vue'),
    beforeEnter: (to, from, next) => {
      if(checkLocale()) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/event/contest/116/result',
    component: () => import('@/views/event/contest/116/result.vue'),
    beforeEnter: (to, from, next) => {
      if(checkLocale()) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/event/contest/113',
    component: () => import('@/views/event/contest/113/index.vue'),
    beforeEnter: (to, from, next) => {
      if(checkLocale()) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/event/contest/113/result',
    component: () => import('@/views/event/contest/113/result.vue'),
    beforeEnter: (to, from, next) => {
      if(checkLocale()) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/event/contest/114',
    component: () => import('@/views/event/contest/114/index.vue'),
    beforeEnter: (to, from, next) => {
      if(checkLocale()) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/event/contest/114/result',
    component: () => import('@/views/event/contest/114/result.vue'),
    beforeEnter: (to, from, next) => {
      if(checkLocale()) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/event/contest/',
    component: () => import('@/views/event/contest/template.vue'),
    beforeEnter: (to, from, next) => {
      if(checkLocale()) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {path: '1', component: () => import('@/views/event/contest/2021052/index.vue')},
      {path: '1/list', component: () => import('@/views/event/contest/2021052/list.vue')},
      {path: '1/result', component: () => import('@/views/event/contest/2021052/result.vue')},

      {path: '2', component: () => import('@/views/event/contest/202105/index.vue')},
      {path: '2/list', component: () => import('@/views/event/contest/202105/list.vue')},
      {path: '2/result', component: () => import('@/views/event/contest/202105/result.vue')},

      {path: '101', component: () => import('@/views/event/contest/202104/index.vue')},
      {path: '101/list', component: () => import('@/views/event/contest/202104/list.vue')},
      {path: '101/result', component: () => import('@/views/event/contest/202104/result.vue')},

      {path: '102', component: () => import('@/views/event/contest/202102/index.vue')},
      {path: '102/list', component: () => import('@/views/event/contest/202102/list.vue')},
      {path: '102/result', component: () => import('@/views/event/contest/202102/result.vue')},

      {path: '103', component: () => import('@/views/event/contest/2020122/index.vue')},
      {path: '103/list', component: () => import('@/views/event/contest/2020122/list.vue')},
      {path: '103/result', component: () => import('@/views/event/contest/2020122/result.vue')},

      {path: '104', component: () => import('@/views/event/contest/202012/index.vue')},
      {path: '104/list', component: () => import('@/views/event/contest/202012/list.vue')},
      {path: '104/result', component: () => import('@/views/event/contest/202012/result.vue')},

      {path: '105', component: () => import('@/views/event/contest/202010/index.vue')},
      {path: '105/list', component: () => import('@/views/event/contest/202010/list.vue')},
      {path: '105/result', component: () => import('@/views/event/contest/202010/result.vue')},

      {path: '106', component: () => import('@/views/event/contest/202005/index.vue')},
      {path: '106/list', component: () => import('@/views/event/contest/202005/list.vue')},
      {path: '106/result', component: () => import('@/views/event/contest/202005/result.vue')},

      {path: '107', component: () => import('@/views/event/contest/202002/index.vue')},
      {path: '107/list', component: () => import('@/views/event/contest/202002/list.vue')},
      {path: '107/result', component: () => import('@/views/event/contest/202002/result.vue')},

      {path: '109', component: () => import('@/views/event/contest/201906/index.vue')},
      {path: '109/list', component: () => import('@/views/event/contest/201906/list.vue')},
      {path: '109/result', component: () => import('@/views/event/contest/201906/result.vue')},

      {path: '108', component: () => import('@/views/event/contest/201910/index.vue')},
      {path: '108/list', component: () => import('@/views/event/contest/201910/list.vue')},
      {path: '108/result', component: () => import('@/views/event/contest/201910/result.vue')},

      {path: '110', component: () => import('@/views/event/contest/201905/index.vue')},
      {path: '110/list', component: () => import('@/views/event/contest/201905/list.vue')},
      {path: '110/result', component: () => import('@/views/event/contest/201905/result.vue')},

      {path: '111', component: () => import('@/views/event/contest/201902/index.vue')},
      {path: '111/list', component: () => import('@/views/event/contest/201902/list.vue')},
      {path: '111/result', component: () => import('@/views/event/contest/201902/result.vue')},

      {path: '112', component: () => import('@/views/event/contest/201901/index.vue')},
      {path: '112/list', component: () => import('@/views/event/contest/201901/list.vue')},
      {path: '112/result', component: () => import('@/views/event/contest/201901/result.vue')},

    ]
  }
];

const checkLocale = () => {
  const lang = store.getters['I18nStore/getCookieLanguage'];
  return lang === 'ja-JP';
};

export const gotoChallengeContentHome = ({
  contentId
}: {
  contentId: number;
}) => {
  router.push(`/challenge/comic/${contentId}`);
};
export const gotoChallengeChapter = ({
  contentId,
  chapterId
}: {
  contentId: number;
  chapterId: number;
}) => {
  router.push(`/challenge/comic/${contentId}/${chapterId}`).catch(() => {});
};
export const gotoChallengeCommentList = ({
  contentId,
  chapterId
}: {
  contentId: number;
  chapterId: number;
}) => {
  router.push(`/challenge/comment-list/${contentId}/${chapterId}`);
};
export const gotoChallengeCommentWrite = ({
  contentId,
  chapterId
}: {
  contentId: number;
  chapterId: number;
}) => {
  router.push(`/challenge/comment-write/${contentId}/${chapterId}`);
};
export const gotoChallengeGenre = ({ genreId }: { genreId: number }) => {
  router.push(`/challenge/genre/${genreId}`);
};

const errorDialogHandler = (dispatch, { result }) => {
  const message = result.message || result.debug;
  if (message) {
    const options = getErrorDialogOptions({
      message
    });
    dispatch('DialogStore/dialog', options, { root: true }).then(() => {
      router.push('/');
    });
  }
};
