import { Element } from "@/models";

/**
 * slides 분리
 * @param elements 
 * @param param1 {
 * 	isWide: 브라우저 너비
 *  maxLen: slide max len
 * }
 * @returns slide array
 */
export const getSlides = (elements: Array<Element>, { isWide, maxLen }: {
	isWide: boolean,
	maxLen: number
}): Array<Element[]> => {
	const copy = [...elements];
	if(isWide) {
		const len = copy.length;
		const add = Math.floor(len % maxLen) === 0 ? 0 : 1;
		const loop = Math.floor(len / maxLen) + add;
		const arr: Array<Element[]> = [];
		for(let i = 0; i < loop; i++) {
			const temp = copy.splice(0, maxLen);
			if(arr.length > 0 && temp.length < maxLen) {
				const pre: Element[] = [];
				for(let ii = 0; ii < maxLen; ii++) {
					const node = elements[(elements.length - 1) - ii];
					pre.push(node);
				}
				arr.push(pre.reverse());
			} else {
				arr.push(temp);
			}
		}
		return arr;
	} else {
		return [[...copy]];
	}
}

/**
 * section type에 맞는 slide max len 계산
 * @param elementUiType 
 * @returns 
 */
export const getSectionMaxLen = (elementUiType: string | undefined): number => {
	switch(elementUiType) {
		case 'genre_basic':
			return 6;
		case 'content_big':
			return 4;
		case 'banner_list':
		case 'banner_swipe':
			return 2;
		default:
			return 5;
	}
}

/**
 * slide navi 인덱스 증가
 * @param slideIndex 
 * @param maxVal 
 * @returns 
 */
export const increaseIndex = (slideIndex: number, maxVal: number): number => {
	if(++slideIndex > (maxVal - 1)) {
		slideIndex = 0;
	}
	return slideIndex;
};

/**
 * slide navi 인덱스 감소
 * @param slideIndex 
 * @param maxVal 
 * @returns 
 */
export const decreaseIndex = (slideIndex: number, maxVal: number): number => {
	if(--slideIndex < 0) {
		slideIndex = (maxVal - 1);
	}
	return slideIndex;
};

export const getLiWidth = (elementUiType: string | undefined) => {
	if(!elementUiType) return 0;
	const margin = 17;
	switch(elementUiType) {
		case 'content_big':
			return 170 + 16;
		case 'genre_basic':
			return 108 + margin;
		case 'banner_list':
			return 356 + margin;
		case 'banner_swipe':
			return 364 + 16;
		default:
			return 132 + margin;
	}
};

export const nextBtnState = (value: number, namoji: number) => {
	switch(value) {
		case 0:
			return false;
		case 1:
			return namoji !== 0;
		default:
			return true;
	}
}

const gotoNext = ({ currentIndex, value, namoji, liWidth, currentX, MAX_SLIDE_LEN, el }) => {
	if(currentIndex === value) {
		if(namoji > 0) {
			currentX = currentX + namoji * liWidth;
			el.style.transform = `translateX(-${currentX}px)`;
			return {
				prev: true,
				next: false,
				currentX
			}
		} else {
			return {
				next: false,
				currentX
			}
		}
	} else {
		currentIndex = currentIndex + 1;
		currentX = currentX + MAX_SLIDE_LEN * liWidth;
		el.value.style.transform = `translateX(-${currentX}px)`;
		return {
			prev: true,
			next: !(namoji === 0 && currentIndex === value),
			currentX,
			currentIndex
		}
	}
}

const gotoPrev = () => {}

export const SectionHelperService = {
	getSlides,
	getSectionMaxLen,
	increaseIndex,
	decreaseIndex,
	getLiWidth,
	nextBtnState,
	gotoNext,
	gotoPrev
}