import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { Banner, Contents, ContentsHomeInfo } from '@/models/contents-home-info';
import { ContentsApiService } from '@/services/contents/contents.api';
import { Chapter, ContentInfo, Popup, Promotion, Section } from '@/models';
import { LibraryApiService, SubscribedType } from '@/services/library/library.api';
import { IconType, ICON_CLOSE, TYPE_ICONS } from '@/commons/constants/icon-types';
import { i18nTxt } from '@/commons/i18n';
import { SingularSdk } from '@/services/singluar/singluar';
import { getErrorDialogOptions } from '@/services';
import router from '@/router';
import { ERROR_CODES } from '@/commons/constants/error-codes';

export interface ContentsHomeStateInterface {
	banners: Banner[];
	content: ContentInfo | null;
	contents: Contents | null;
	contentHomeInfo: ContentsHomeInfo | null;
	popups: Popup[];
	promotions: Promotion[];
	tabType: null | 'EPISODE' | 'VOLUME';
	contentType: string;
	contentId: number | null;
	introTheme: string;
	showDetail: IconType;
	recommends: Section[];
	shownChapters: Chapter[]
	isSubscribed: boolean;
	visual: number;
	orderType: string;
	prevRouteName: string;
}

export const ContentsHomeStore: Module<ContentsHomeStateInterface, RootState> = {
	namespaced: true,
	state: {
		banners: [],
		// episode: null,
		// volume: null,
		content: null,
		contents: null,
		contentHomeInfo: null,
		popups: [],
		promotions: [],
		tabType: null,
		contentType: '',
		contentId: null,
		introTheme: 'unset',
		showDetail: TYPE_ICONS.ICON_CLOSE,
		recommends: [],
		shownChapters: [],
		isSubscribed: false,
		visual: 1,
		orderType: 'oldest',
		prevRouteName: ''
	},
	getters: {
		content: state => state.content,
		contents: state => state.contents,
		contentsHomeInfo: state => state.contentHomeInfo,
		// content: state => state.contentsHome.content,
		// episode: state => state.episode,
		// volume: state => state.volume,
		banners: state => state.banners,
		popups: state => state.popups,
		promotions: state => state.promotions,
		tabType: state => state.tabType,
		contentType: state => state.contentType,
		contentId: state => state.contentId,
		introTheme: state => state.introTheme,
		showDetail: state => state.showDetail,
		mapContentType(state): 'key_visual' | 'cover' {
			switch (state.contentType) {
				case 'magazine_comic':
				case 'novel':
				case 'magazine_novel': {
					return 'cover';
				}
				case 'comic':
				default:
					return 'key_visual';
			}
		},
		recommends: state => state.recommends,
		shownChapters: state => state.shownChapters,
		isSubscribed: state => state.isSubscribed,
		visual: state => state.visual,
		orderType: state => state.orderType,
		prevRouteName: state => state.prevRouteName
	},
	mutations: {
		content(state, value: ContentInfo | null) {
			state.content = value;
		},
		contents(state, value: Contents | null) {
			state.contents = value;
		},
		contentsHomeInfo(state, value: ContentsHomeInfo | null) {
			state.contentHomeInfo = value;
		},
		banners(state, value: Banner[]) {
			state.banners = value;
		},
		popups(state, value: Popup[]) {
			state.popups = value;
		},
		promotions(state, value: Promotion[]) {
			state.promotions = value;
		},
		introTheme(state, value: string) {
			state.introTheme = value;
		},
		showDetail(state, value: IconType) {
			state.showDetail = value;
		},
		contentType(state, value: string) {
			state.contentType = value;
		},
		contentId(state, value: number | null) {
			state.contentId = value;
		},
		recommends(state, value: Section[]) {
			state.recommends = value;
		},
		tabType(state, value: 'EPISODE' | 'VOLUME' | null) {
			state.tabType = value;
		},
		shownChapters(state, value: Chapter[]) {
			state.shownChapters = value;
		},
		isSubscribed(state, value: boolean) {
			state.isSubscribed = value;
		},
		visual(state, value: number) {
			state.visual = value;
		},
		orderType(state, value: string) {
			state.orderType = value;
		},
		prevRouteName(state, value: string) {
			state.prevRouteName = value;
		}
	},
	actions: {
		async initContentsHome({ commit, dispatch }, { contentType, contentId }) {
			try {
				const data = await ContentsApiService.getContents(contentType, contentId);
				const contentsHomeInfo = new ContentsHomeInfo(data as ContentsHomeInfo);
				const { episode, volume } = contentsHomeInfo;
				let contents: Contents | null = null;

				if (episode.getContent() && volume.getContent()) {
					contents = episode;
				} else if (episode.getContent() || volume.getContent()) {
					contents = episode.getContent() ? episode : volume;
				} else {
					return;
				}
				commit('contentsHomeInfo', contentsHomeInfo);
				commit('tabType', contents.name);
				commit('contentType', contentType);
				commit('contentId', contentId);
				commit('isSubscribed',
					contents.getContent()?.activity.subscribed
						? contents.getContent()?.activity.subscribed
						: false
				);
				dispatch('setContents', contents);
				return { contents };
			} catch (e: any) {
				const { result } = e;
				switch (result.code) {
					case ERROR_CODES.CODE_428:	// 성인 on/off 권한
					case ERROR_CODES.CODE_451:	// 성인 인증
					case ERROR_CODES.CODE_401:	// 로그인 오류
						return { code: result.code };
					case ERROR_CODES.CODE_404:
					case ERROR_CODES.CODE_303:
					default:
						errorDialogHandler(dispatch, { result });
						break;
				}
			}
		},
		resetIntroTheme({ commit }) {
			commit('introTheme', '#000');
		},
		tabActive({ commit, dispatch, state }, { tabName }: { tabName: 'EPISODE' | 'VOLUME' }) {
			if (!state.contentHomeInfo) return;
			const contents: Contents | null = (tabName === 'EPISODE') ? state.contentHomeInfo?.episode : state.contentHomeInfo?.volume;
			if (contents?.isExist()) {
				dispatch('setContents', contents);
				commit('tabType', tabName);
			}
		},
		setContents({ commit, dispatch }, contents: Contents) {
			commit('content', contents.getContent());
			commit('recommends', contents.recommends);
			commit('banners', contents.banners);
			commit('popups', contents.popups);
			commit('promotions', contents.promotions);
			commit('shownChapters', contents.content ? contents.content.chapters : []);
			commit('orderType', 'oldest');
			dispatch('setIntroTheme', {
				content: contents.content
			});
		},
		reverseOrder({ commit, state }) {
			let list = state.shownChapters;
			if (state.orderType) {
				list.reverse();
			}
			commit('shownChapters', list);
		},
		async subscribed({ state, commit, dispatch, rootState }) {
			try {
				const param: SubscribedType = {
					contentType: state.contentType,
					contentId: state.contentId
				};
				if (state.isSubscribed) {
					await LibraryApiService.deleteSubscribed(param);
				} else {
					await LibraryApiService.setSubscribed(param);
					if (state.contentId) {
						const singular: SingularSdk = rootState.SingularStore.singular;
						singular.addToFavorite(state.contentId);
					}
				}
				commit('isSubscribed', !state.isSubscribed);
				dispatch('GlobalStore/popNotification', {
					message: state.isSubscribed
						? i18nTxt('added_to_subscribed_list')
						: i18nTxt('removed_from_subscribed_list')
				}, {
					root: true
				});
				return 200;
			} catch (e) {
				return e;
			}
		},
		showDetailContent({ state, commit }) {
			commit('showDetail', state.showDetail.type === ICON_CLOSE.type
				? TYPE_ICONS.ICON_OPEN
				: TYPE_ICONS.ICON_CLOSE
			);
		},
		closeShowDetail({ commit }) {
			commit('showDetail', TYPE_ICONS.ICON_CLOSE);
		},
		setIntroTheme({ commit, getters, dispatch }, { content }) {
			const mapContentType = getters['mapContentType'];
			if (mapContentType === 'key_visual') {
				let { themeColor } = content;
				if (!themeColor && (themeColor && themeColor.length) === 0) {
					themeColor = '#000'; // 기본값
				}
				commit('UIStore/headerBackground', themeColor, {
					root: true
				});
				commit('introTheme', themeColor);
			} else if (mapContentType === 'cover') {
				commit(
					'UIStore/headerBackground',
					`url(${content.getThumbnail('cover')}) 50% 50% / cover no-repeat`, {
					root: true
				}
				);
				dispatch('resetIntroTheme');
			}
		}
	}
}

const errorDialogHandler = (dispatch, { result }) => {
	const message = result.message || result.debug;
	if (message) {
		const options = getErrorDialogOptions({
			message
		});
		dispatch('DialogStore/dialog', options, { root: true }).then(() => {
			router.push('/');
		});
	}
}